import React from 'react';
import {Text, StyleSheet, TouchableOpacity, Alert, View, ActivityIndicator} from 'react-native';
import appColor from '../../constants/other/colors';
import appfont from '../../constants/other/font';

const ButtonWeb = ({text, onPress, height, width, type, loading}) => {

  return (
    <>
    { loading ?
        <View style={ type == 1 ? {height:height, 
                            width:width, 
                            backgroundColor:appColor.primary,
                            justifyContent:'center',
                            alignItems:'center',
                            borderRadius:7,
                             }: 
                             {
                            height:height, 
                            width:width, 
                            justifyContent:'center',
                            alignItems:'center',
                            borderRadius:7,
                            borderWidth:1,
                            borderColor:appColor.primary
                             }
                             } 
    onPress={onPress}>
    <ActivityIndicator size='large' color={type == 1 ? appColor.background : appColor.primary}/>
    </View>
    :
    <TouchableOpacity style={ type == 1 ? {height:height, 
                            width:width, 
                            backgroundColor:appColor.primary,
                            justifyContent:'center',
                            alignItems:'center',
                            borderRadius:7,
                             }: 
                             {
                            height:height, 
                            width:width, 
                            justifyContent:'center',
                            alignItems:'center',
                            borderRadius:7,
                            borderWidth:1,
                            borderColor:appColor.primary
                             }
                             } 
    onPress={onPress}>
      <Text style={type == 1 ?
                    {
                        fontFamily:appfont.bold,
                        color:appColor.background,
                        fontSize:14
                    }:{
                        fontFamily:appfont.bold,
                        color:appColor.primary,
                        fontSize:14
                    }
      }>{text}</Text>
    </TouchableOpacity>
    }
    </>
  );
};

export default ButtonWeb;