import React from 'react'
import { View, Text, StyleSheet, Platform, Dimensions, TouchableOpacity, ScrollView } from 'react-native'
import Svg, { Circle, Rect} from 'react-native-svg';
import { SvgXml } from 'react-native-svg';
import { SafeAreaView } from 'react-native-safe-area-context';
import appColor from '../../constants/other/colors';
import appfont from '../../constants/other/font';
import RightSvg from '../../constants/svg/Right';
import LeftSvg from '../../constants/svg/Left';
import Landing3Svg from '../../constants/svg/Landing3';
import { useNavigation } from '@react-navigation/native';

const width = Dimensions.get('window').width
const height = Dimensions.get('window').height


const Splash3 = () => {
    const navigation = useNavigation()
    return(
        <View style={styles.main}>
            <SafeAreaView style={styles.sfview}>
                        <View style={styles.topsvg}>
                            <Landing3Svg height={width-50} width={width-50} />
                        </View>
                        <View style={{paddingLeft:25, paddingRight:25}}>
                            <Text style={styles.htxt}>Free Credit Score Analytics</Text>
                            <Text style={styles.ptxt}>Get your free credit score report and take control of your financial future</Text>
                        </View>
                <View style={styles.btm}>
                    <TouchableOpacity style={styles.crcard1} onPress={() => navigation.navigate('Signin', {fieldtype : 1})}>
                        <Text style={styles.srtxt1}>Create Account</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.crcard2} onPress={() => navigation.navigate('Signin', {fieldtype : 0})}>
                        <Text style={styles.srtxt2}>Login</Text>
                    </TouchableOpacity>
                    
                </View>
            </SafeAreaView>
        </View>
    )
}

export default Splash3

const styles = StyleSheet.create({
    main: {
      flex: 1,
      backgroundColor:appColor.background,
      justifyContent:'space-between'
    },
    sfview:{
        flex:1,
        justifyContent:'space-between',
        paddingTop:50,
        paddingBottom:50
    },
    topsvg:{
        alignItems:'center',
        justifyContent:'center',
    },
    htxt:{
        fontFamily:appfont.bold,
        fontSize:25,
        color:appColor.primary,
    },
    ptxt:{
        fontFamily:appfont.regular,
        fontSize:15,
        color:appColor.txgray,
        marginTop:15
    },
    btm:{
        paddingLeft:25,
        paddingRight:25
    },
    crcard1:{
        height:49,
        backgroundColor:appColor.primary,
        borderRadius:20,
        justifyContent:'center',
        alignItems:'center'
    },
    crcard2:{
        height:49,
        borderRadius:20,
        justifyContent:'center',
        alignItems:'center',
        borderWidth:1,
        borderColor:appColor.primary,
        marginTop:18
    },
    srtxt1:{
        fontFamily:appfont.bold,
        color:appColor.background,
        fontSize:15
    },
    srtxt2:{
        fontFamily:appfont.bold,
        color:appColor.primary,
        fontSize:15
    }
    
  });
  
  
  
  
  
  