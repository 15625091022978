import * as React from "react"
import Svg, { Path } from "react-native-svg"

function CircleRightSvg(props) {
  return (
    <Svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M12.5 24.999a12.499 12.499 0 1112.499-12.5 12.513 12.513 0 01-12.5 12.5z"
        fill="#2C3AD9"
      />
      <Path
        d="M12.5 0c-.846 0-1.69.085-2.517.255a12.501 12.501 0 010 24.49A12.501 12.501 0 1012.499 0z"
        fill="#2C3AD9"
      />
      <Path
        d="M10.59 16.896c-.333 0-.653-.133-.89-.369L7.243 14.07a1.259 1.259 0 111.78-1.78l1.569 1.569 5.386-5.386a1.257 1.257 0 111.78 1.78l-6.277 6.275a1.258 1.258 0 01-.89.369z"
        fill="#fff"
      />
    </Svg>
  )
}

export default CircleRightSvg
