
import React, {useState, useEffect, useRef, } from 'react'
import { View, Text, StyleSheet, TouchableOpacity, ScrollView, Dimensions, TextInput,} from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context';
import appColor from '../../constants/other/colors'
import SideMenuSvg from '../../constants/svg/sidemenu';
import BellSvg from '../../constants/svg/bell';
import appfont from '../../constants/other/font';
import { useNavigation } from '@react-navigation/native';
import OfferSvg from '../../constants/svg/offer';
import ProfileSvg from '../../constants/svg/profilesv';
import * as SecureStore from 'expo-secure-store';
import { useDispatch, useSelector } from 'react-redux'
import { userToken, userData } from '../../toolkit/slice'

const width = Dimensions.get('window').width
const height = Dimensions.get('window').height


const ProfileScreen = () => {
    const scrollViewRef = useRef();
    const [currentPage, setCurrentPage] = useState(0);
    const navigation = useNavigation()
    const userdata = useSelector((state) => state.appredux.userData)
    const [firstName, setFirstName] = useState(userdata.firstName)

    const dispatch = useDispatch()

    const handleLogout = async() => {
        try {
            await SecureStore.deleteItemAsync('token')
            dispatch(userToken(null))
        }catch (error) {
            console.log(error)
        }
    }
    
    return(
        <View style={styles.main}>
            <SafeAreaView style={styles.sfview}>
            <TouchableOpacity style={{marginLeft:24}} onPress={() => navigation.openDrawer()}>
            <SideMenuSvg height={21.5} width={32.5}/>
            </TouchableOpacity>
            <ScrollView style={styles.scrl} showsVerticalScrollIndicator={false}>
                <View style={styles.top}>
                    <Text style={styles.wltxt}>Profile</Text>
                    <TouchableOpacity>
                        <BellSvg height={20} width={17} />
                    </TouchableOpacity>
                </View>
                <View style={styles.plv}>
                    <ProfileSvg height={width/3.5} width={width/3.5} />
                </View>

                <View style={styles.inptview}>
                    <View style={styles.inptv2}>
                        <Text style={styles.fltxt}>First Name</Text>
                        <TextInput 
                            value={firstName}
                            style={styles.txtinpt}
                            onChangeText={(text) => setFirstName(text)}
                        />
                    </View>
                </View>
                <TouchableOpacity style={styles.smbtn}>
                    <Text style={styles.smtxt}>Save Changes</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.lgbtn} onPress={() => handleLogout()}>
                    <Text style={styles.lgtxt}>Log Out</Text>
                </TouchableOpacity>
            </ScrollView>
            </SafeAreaView>
        </View>
    )
}

export default ProfileScreen

const styles = StyleSheet.create({
    main:{
        flex:1,
        backgroundColor:appColor.background
    },
    sfview:{
        flex:1
    },
    top:{
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        paddingLeft:24,
        paddingRight:24,
        marginTop:20
    },
    wltxt:{
        fontFamily:appfont.bold,
        color:appColor.txheadblack,
        fontSize:25,
    },
    plv:{
        justifyContent:'center',
        alignItems:'center',
        marginTop:40
    },
    inptview:{
        paddingLeft:24,
        paddingRight:24,
        marginTop:22,
    },
    inptv2:{
        height:58,
        borderRadius:15,
        backgroundColor:appColor.gray,
        paddingLeft:29,
        paddingRight:29,
        width:width-48
    },
    fltxt:{
        fontFamily:appfont.semibold,
        fontSize:10,
        color:'#909090',
        marginTop:6
    },
    txtinpt:{
        height:30,
        fontFamily:appfont.bold,
        fontSize:14,
        color:appColor.txheadblack,
        width:width-110
    },
    smbtn:{
        marginTop:50,
        marginLeft:24,
        marginRight:24,
        borderRadius:20,
        height:49,
        backgroundColor:appColor.primary,
        justifyContent:'center',
        alignItems:'center'
    },
    smtxt:{
        fontFamily:appfont.bold,
        fontSize:15,
        color:appColor.background
    },
    lgbtn:{
        alignItems:'center',
        justifyContent:'center',
        marginTop:20
    },
    lgtxt:{
        fontFamily:appfont.bold,
        fontSize:15,
        color:appColor.primary
    }
    
})