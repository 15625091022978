import React from 'react'
import { View, Text, StyleSheet, Platform, Dimensions, TouchableOpacity, ScrollView } from 'react-native'
import Svg, { Circle, Rect} from 'react-native-svg';
import { SvgXml } from 'react-native-svg';
import { SafeAreaView } from 'react-native-safe-area-context';
import appColor from '../../constants/other/colors';
import appfont from '../../constants/other/font';
import RightSvg from '../../constants/svg/Right';
import LeftSvg from '../../constants/svg/Left';
import { useNavigation } from '@react-navigation/native';
import Landing2Svg from '../../constants/svg/Landing2';

const width = Dimensions.get('window').width
const height = Dimensions.get('window').height


const Splash2 = () => {
    const navigation = useNavigation()
    return(
        <View style={styles.main}>
            <SafeAreaView style={styles.sfview}>
                        <View style={styles.topsvg}>
                            <Landing2Svg height={width-50} width={width-50} />
                        </View>
                        <View style={{paddingLeft:25, paddingRight:25}}>
                            <Text style={styles.htxt}>Tailored Repayment Flexibility</Text>
                            <Text style={styles.ptxt}>Flexible loan with repayment terms that work for you</Text>
                        </View>
                <View style={styles.btm}>
                    <View style={styles.btmleft}>
                        <View style={styles.btltop}>
                            <View style={styles.btl2}></View><View style={styles.btl1}></View><View style={styles.btl2}></View>
                        </View>
                        <TouchableOpacity style={styles.btlbtm} onPress={() => navigation.navigate('Splash3')}>
                            <Text style={styles.sktxt}>Skip</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={styles.btmright}>
                        <TouchableOpacity onPress={() => navigation.goBack()}>
                            <LeftSvg colour={"#2C3AD9"}/>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => navigation.navigate('Splash3')}>
                            <RightSvg />
                        </TouchableOpacity>
                    </View>
                </View>
            </SafeAreaView>
        </View>
    )
}

export default Splash2

const styles = StyleSheet.create({
    main: {
      flex: 1,
      backgroundColor:appColor.background,
      justifyContent:'space-between'
    },
    sfview:{
        flex:1,
        justifyContent:'space-between',
        paddingTop:50,
        paddingBottom:50
    },
    topsvg:{
        alignItems:'center',
        justifyContent:'center',
    },
    htxt:{
        fontFamily:appfont.bold,
        fontSize:25,
        color:appColor.primary,
    },
    ptxt:{
        fontFamily:appfont.regular,
        fontSize:15,
        color:appColor.txgray,
        marginTop:15
    },
    btm:{
        flexDirection:'row',
        paddingLeft:25,
        justifyContent:'space-between',
        alignItems:'center'
    },
    btltop:{
        flexDirection:'row',
        alignItems:'center'
    },
    btl1:{
        height:5,
        width:26,
        backgroundColor:appColor.primary,
        borderRadius:10,
        marginRight:6
    },
    btl2:{
        width:5,
        height:6,
        borderWidth:1,
        borderColor:appColor.primary,
        borderRadius:10,
        marginRight:6
    },
    btlbtm:{
        height:29,
        width:76,
        borderRadius:5,
        alignItems:'center',
        justifyContent:'center',
        backgroundColor:appColor.background,
        marginTop:49,
        ...Platform.select({
            android: { elevation: 5 },
            ios: { 
              shadowColor: '#000',
              shadowOffset: { width: 0, height: 2 },
              shadowOpacity: 0.25,
              shadowRadius: 3.84,
            },
        })
    },
    btmright:{
        flexDirection:'row',
        alignItems:'center'
    },
    sktxt:{
        fontFamily:appfont.semibold,
        fontSize:12,
        color:appColor.primary
    }
  });
  
  
  
  
  
  