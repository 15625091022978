import React, {useRef, useState, useEffect} from "react";
import { StyleSheet, View, Text, ScrollView, Dimensions, Image, TouchableOpacity, useWindowDimensions} from "react-native";
import appColor from "../../constants/other/colors";
import appfont from "../../constants/other/font";
import WebHeader from "../../components/WebComponent/Header";
import ButtonWeb from "../../components/WebComponent/WebButton";
import MidlleSvg from "../../constants/svg/web/middlesvg";
import PlayStoreSvg from "../../constants/svg/web/playStore";
import AppStoreSvg from "../../constants/svg/web/appStore";
import Footer from "../../components/WebComponent/Footer";
import LineVectorSvg from "../../constants/svg/web/linevector";
import MobileWebHeader from "../../components/WebComponent/MobileWebHeader";
import { useNavigation } from "@react-navigation/native";
import AsyncStorage from '@react-native-async-storage/async-storage';


const WebHome = () => {
    const navigation = useNavigation()
    const { width } = useWindowDimensions();
    const screenWidth = width;
    const lagrgescreen = width > 600

    const creditScoreRef = useRef(null)

    const [tknsts, setTokenSts] = useState(null) 

    const getData = async () => {
        try {
          const value = await AsyncStorage.getItem('token');
          if (value !== null) {
            setTokenSts(value)
          }
        } catch (e) {
          // error reading value
        }
      };

      useEffect(() => {
        getData()
      },[])


        function getStarted(){
            if(tknsts){
                navigation.navigate('MobileHome')
            }else{
                navigation.navigate('Signin', {fieldtype : 1})
            }
        }

    const handleButtonPress = (ref) => {
        ref.current?.scrollTo({ y: 0, animated: true });
      };

      const styles = StyleSheet.create({
        main:{
            flex:1,
            backgroundColor:appColor.background
        },
        topbnr:{
            height:550,
            backgroundColor:'#F9FDFF'
        },
        scrl:{
            marginTop:4
        },
        thtxt:{
            fontFamily:appfont.bold,
            color:appColor.txheadblack,
            fontSize:lagrgescreen ? 48 : 28
        },
        tpbn1:{
            width:lagrgescreen ? screenWidth/2 : width-20,
            height:550,
            justifyContent:'center',
            paddingLeft: lagrgescreen ? 80 : 10
        },
        prtxt:{
            fontFamily:appfont.regular,
            color:'#595757',
            fontSize:25,
            marginTop:lagrgescreen ? 18 : 12
        },
        img:{
            height:697.14,
            width:584,
            alignSelf:'flex-end',
            marginTop:-520
        },
        img2:{
            height:(width-50)*1.19,
            width:width-50,
            marginTop:10,
            resizeMode:'contain'
        },
        mobile2:{
            height:595,
            width:515,
            resizeMode:'contain'
        },
        div2:{
            paddingLeft:lagrgescreen ? 80 : 10,
            paddingRight:lagrgescreen ?80 : 10,
            paddingTop:10,
            flexDirection:'row',
            alignItems:'center',
            flexWrap:'wrap'
        },
        dv2r:{
            width:lagrgescreen ? screenWidth-675 : width-20,
            marginTop:-80
        },
        txh1:{
            fontFamily:appfont.bold,
            fontSize:35,
            color:appColor.txheadblack,
            marginBottom:19,
            width:lagrgescreen ? 500 : width-20
        },
        txh2:{
            fontFamily:appfont.bold,
            fontSize:35,
            color:appColor.txheadblack,
            marginBottom:19,
        },
        txp1:{
            fontFamily:appfont.regular,
            fontSize:20,
            color:'#767676',
            marginBottom:19,
            textAlign:'justify'
        },
        line1:{
            height:5,
            width:72,
            backgroundColor:appColor.primary,
            marginBottom:19
        },
        btbnr:{
            height:771,
            backgroundColor:'#F9FDFF',
            flexDirection:'row',
            marginTop:30,
            flexWrap:'wrap'
        },
        btf:{
            width:lagrgescreen ? screenWidth/2 : width-20,
            height:771,
            paddingLeft:lagrgescreen ? 80 : 10,
            justifyContent:'center'
        },
        altxt:{
            fontFamily:appfont.semibold,
            fontSize:16,
            color:appColor.txheadblack,
            marginTop:50
        },
        storecard:{
            flexDirection:'row',
            marginTop:30,
            flexWrap:'wrap'
        },
        btr:{
            width:screenWidth/2,
            height:771,
            paddingRight:80,
            justifyContent:'flex-end',
            alignItems:'flex-end'
        },
        img3:{
            height:680,
            width:380,
            resizeMode:'contain'
        },
        linvec:{
            marginTop:300
        }
    
    })

    return (
        <View style={styles.main}>
            {
                width > 1200 ?
                <WebHeader />
                :
                <MobileWebHeader />
            }
            
            <ScrollView style={styles.scrl}>
                <View style={styles.topbnr}>
                    <View style={styles.tpbn1}>
                        <Text style={styles.thtxt}>Get the Funding You Need with Our Easy and Fast Loan Options</Text>
                        <Text style={styles.prtxt}>Experience Hassle-Free Application, Free Credit Score Report, and Quick Approval Process for Your Financial Needs</Text>
                        <View style={{marginTop:43}}>
                        <ButtonWeb height={47} width={177} type={1} text={'Get Started'} onPress={() => getStarted() }/>
                        </View>
                    </View>
                    <View>
                    <Image source={require('../../constants/svg/web/mobile.png')} style={lagrgescreen ? styles.img : styles.img2}/>
                    </View>
                </View>
                <View style={styles.div2}>
                        <Image source={require('../../constants/svg/web/mobile2.png')} style={styles.mobile2} />
                        <View style={styles.dv2r}>
                            <Text style={styles.txh1}>Unlock Awesome Loan Offers</Text>
                            <View style={styles.line1}></View>
                            <Text style={styles.txp1}>Looking for the best loan offers? Look no further! Unlock a range of awesome loan offers that can help you achieve your financial goals. Whether you need funds for a new car, home renovation, or a dream vacation, our loan options are tailored to fit your unique needs. So why wait? Unlock your awesome loan offers today and start making your dreams a reality!</Text>
                            <ButtonWeb height={47} width={177} type={1} text={'Get Started'} onPress={() => getStarted()}/>
                        </View>
                    </View>
                    <View style={{justifyContent:'center', alignItems:'center', marginTop:lagrgescreen ? 0 : 20}}>
                        <Text style={styles.txh2}>How It Works</Text>
                        <View style={styles.line1}></View>
                        <Text style={styles.txp1}>Get a loan in 3 easy steps</Text>
                        {
                            lagrgescreen ? 
                            <MidlleSvg />
                            :
                            null
                        }
                    </View>

                    <View style={styles.btbnr} ref={creditScoreRef}>
                    <View style={styles.btf}>
                        <Text style={styles.txh2}>Fee Credit Score</Text>
                        <View style={styles.line1}></View>
                        <Text style={styles.txp1}>Knowing your credit score is key to understanding your financial health. With our free credit score report, you can stay on top of your credit score and take steps to improve it. Our report is easy to access and provides detailed insights into your credit history, so you can make informed decisions about your finances.</Text>
                        <Text style={styles.altxt}>or Get the App</Text>
                        <View style={styles.storecard}>
                            <TouchableOpacity>
                            <PlayStoreSvg />
                            </TouchableOpacity>
                            <TouchableOpacity style={{marginLeft:lagrgescreen ? 20 : 0}}>
                            <AppStoreSvg />
                            </TouchableOpacity>

                        </View>
                    </View>
                    <View style={styles.btr}>
                        <Image source={require('../../constants/svg/web/mobile3.png')} style={styles.img3} />
                    </View>
                    </View>
                    <Footer />
            </ScrollView>
        </View>
    )
}


export default WebHome