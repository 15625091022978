import * as React from "react"
import Svg, { Path } from "react-native-svg"

function LeftSvg(props) {
  return (
    <Svg
      width={26}
      height={13}
      viewBox="0 0 26 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M.297 7.242h.001l5.298 5.452a.99.99 0 001.434-.003 1.074 1.074 0 00-.004-1.483L3.47 7.548H24.94c.56 0 1.014-.47 1.014-1.048 0-.58-.454-1.049-1.014-1.049H3.47l3.556-3.66A1.074 1.074 0 007.03.31.99.99 0 005.596.305L.298 5.757a1.075 1.075 0 000 1.485z"
        fill={props.colour}
        fillOpacity={1}
      />
    </Svg>
  )
}

export default LeftSvg