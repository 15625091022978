import React from 'react';
import { TextInput, StyleSheet, Dimensions, View, Text } from 'react-native';
import appColor from '../constants/other/colors';
import appfont from '../constants/other/font';

const screenWidth = Dimensions.get('window').width
const width = Dimensions.get('window').width

export default function CustomTextInput({ placeholder, value, onChangeText, name, maxLength }) {
  return (
        <View style={styles.inptv2}>
            <Text style={styles.fltxt}>{name}</Text>
            <TextInput 
                value={value}
                style={styles.txtinpt}
                onChangeText={onChangeText}
                placeholder={placeholder}
                placeholderTextColor={appColor.gray}
                maxLength={maxLength}
            />
        </View>
  );
}

const styles = StyleSheet.create({
    inptv2:{
        height:58,
        borderRadius:15,
        backgroundColor:appColor.gray,
        paddingLeft:29,
        paddingRight:29,
        width:screenWidth-48
    },
    fltxt:{
        fontFamily:appfont.semibold,
        fontSize:10,
        color:'#909090',
        marginTop:6
    },
    txtinpt:{
        height:30,
        fontFamily:appfont.bold,
        fontSize:14,
        color:appColor.txheadblack,
        width:width-110
    },
});
