import React from 'react';
import { TextInput, StyleSheet, Dimensions, View, Text, TouchableOpacity} from 'react-native';
import appColor from '../constants/other/colors';
import appfont from '../constants/other/font';

const screenWidth = Dimensions.get('window').width

export default function CustomButton({name, onPress}) {
  return (
        <TouchableOpacity onPress={onPress} style={styles.btn}>
            <Text style={styles.smtxt}>{name}</Text>
        </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
    btn:{
        height:49,
        backgroundColor:appColor.primary,
        borderRadius:20,
        justifyContent:'center',
        alignItems:'center',
        marginLeft:24,
        marginRight:24,
        marginTop:50
    },
    smtxt:{
        fontFamily:appfont.bold,
        fontSize:15,
        color:appColor.background,
    },
});
