import * as React from "react"
import Svg, { Circle, G, Path, Defs, ClipPath } from "react-native-svg"

function BusinesLoanSvg(props) {
  return (
    <Svg
      width={props.width}
      height={props.height}
      viewBox="0 0 67 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Circle
        cx={33.3375}
        cy={33.1521}
        r={33.1521}
        fill="#F2F2FF"
        fillOpacity={0.55}
      />
      <G clipPath="url(#clip0_120_1273)">
        <Path
          d="M24.642 22.582h-8.301v28.354h8.301V22.582zM50.334 22.582h-8.301v28.354h8.301V22.582z"
          fill="#EFEFEF"
        />
        <Path
          d="M50.334 22.582h-2.147v28.354h2.147V22.582zM43.106 22.582h-17.39v28.354h17.39V22.582z"
          fill="#E1E1E3"
        />
        <Path d="M40.96 22.582H23.568v28.354h17.39V22.582z" fill="#E1E1E3" />
        <Path d="M42.033 18.589h-17.39v32.348h17.39V18.589z" fill="#EFEFEF" />
        <Path
          d="M24.642 22.582h-8.301v-1.847a1.074 1.074 0 011.073-1.073h7.228v2.92zm25.692 0h-8.301v-2.92h7.228a1.074 1.074 0 011.073 1.073v1.847z"
          fill="#AEADB3"
        />
        <Path
          d="M49.261 19.662h-2.147a1.074 1.074 0 011.074 1.073v1.847h2.147v-1.847a1.074 1.074 0 00-1.074-1.073z"
          fill="#8B8893"
        />
        <Path
          d="M25.716 15.368h15.243a1.073 1.073 0 011.074 1.073v2.147h-17.39v-2.147a1.074 1.074 0 011.073-1.073z"
          fill="#AEADB3"
        />
        <Path
          d="M40.96 15.368h-2.148a1.074 1.074 0 011.074 1.073v2.147h2.147v-2.147a1.074 1.074 0 00-1.074-1.073z"
          fill="#8B8893"
        />
        <Path
          d="M38.813 42.635v8.301h-10.95v-8.301a1.073 1.073 0 011.073-1.074h8.803a1.073 1.073 0 011.074 1.074z"
          fill="#C8C5CC"
        />
        <Path
          d="M37.739 41.561h-2.147a1.073 1.073 0 011.073 1.074v8.301h2.147v-8.301a1.073 1.073 0 00-1.073-1.074z"
          fill="#AEADB3"
        />
        <Path
          d="M33.356 31.592a5.385 5.385 0 113.808-1.575 5.35 5.35 0 01-3.808 1.575z"
          fill="#2C3AD9"
        />
        <Path
          d="M37.164 22.398a5.35 5.35 0 00-3.808-1.574c-.3 0-.599.023-.894.072a5.384 5.384 0 010 10.62 5.448 5.448 0 003.886-.836 5.385 5.385 0 00.816-8.286v.005z"
          fill="#2C3AD9"
        />
        <Path
          d="M33.356 32.129a5.923 5.923 0 114.188-1.735 5.884 5.884 0 01-4.188 1.735zm0-10.771a4.85 4.85 0 103.428 1.42 4.815 4.815 0 00-3.428-1.42z"
          fill="#2C3AD9"
        />
        <Path
          d="M32.765 27.236h-1.354a.537.537 0 110-1.073h1.354a1.128 1.128 0 000-2.254h-.972a.537.537 0 110-1.074h.972a2.201 2.201 0 010 4.401z"
          fill="#fff"
        />
        <Path
          d="M35.342 23.909h-3.93a.537.537 0 110-1.074h3.93a.537.537 0 010 1.074zm0 1.637h-3.93a.537.537 0 010-1.074h3.93a.537.537 0 110 1.074zm-.977 4.58a.533.533 0 01-.374-.153l-2.977-2.892a.538.538 0 01.75-.77l2.976 2.892a.537.537 0 01-.375.922z"
          fill="#fff"
        />
        <Path
          d="M33.338 39.593a.537.537 0 01-.537-.537v-5.37a.537.537 0 011.073 0v5.373a.537.537 0 01-.536.534z"
          fill="#19AC66"
        />
        <Path
          d="M33.337 39.593a.536.536 0 01-.379-.157l-1.717-1.717a.535.535 0 01.169-.883.537.537 0 01.59.123l1.337 1.341 1.342-1.34a.537.537 0 11.758.759l-1.717 1.717a.534.534 0 01-.383.157z"
          fill="#19AC66"
        />
        <Path
          d="M33.874 41.561v9.375a.536.536 0 11-1.073 0v-9.375h1.073z"
          fill="#AEADB3"
        />
        <Path
          d="M22.198 24.73h-3.413a.286.286 0 00-.287.286v2.522c0 .158.128.286.287.286h3.413a.286.286 0 00.287-.286v-2.522a.286.286 0 00-.287-.286zM22.198 29.97h-3.413a.286.286 0 00-.287.287v2.522c0 .158.128.286.287.286h3.413a.286.286 0 00.287-.286v-2.522a.286.286 0 00-.287-.286zM22.198 35.213h-3.413a.286.286 0 00-.287.286v2.522c0 .158.128.286.287.286h3.413a.286.286 0 00.287-.286v-2.522a.286.286 0 00-.287-.286zM22.198 40.454h-3.413a.286.286 0 00-.287.286v2.522c0 .158.128.286.287.286h3.413a.286.286 0 00.287-.286V40.74a.286.286 0 00-.287-.286zM22.198 45.695h-3.413a.286.286 0 00-.287.286v2.522c0 .158.128.286.287.286h3.413a.286.286 0 00.287-.286V45.98a.286.286 0 00-.287-.286zM47.89 24.73h-3.413a.286.286 0 00-.286.286v2.522c0 .158.128.286.286.286h3.414a.286.286 0 00.286-.286v-2.522a.286.286 0 00-.286-.286z"
          fill="#548A3E"
        />
        <Path
          d="M47.89 24.73h-1.073a.287.287 0 01.286.286v2.522a.286.286 0 01-.286.286h1.073a.287.287 0 00.287-.286v-2.522a.286.286 0 00-.287-.286zM47.89 29.97h-3.413a.286.286 0 00-.286.287v2.522c0 .158.128.286.286.286h3.414a.286.286 0 00.286-.286v-2.522a.286.286 0 00-.286-.286zM47.89 35.213h-3.413a.286.286 0 00-.286.286v2.522c0 .158.128.286.286.286h3.414a.286.286 0 00.286-.286v-2.522a.286.286 0 00-.286-.286z"
          fill="#548A3E"
        />
        <Path
          d="M47.89 29.97h-1.073a.286.286 0 01.286.287v2.522a.286.286 0 01-.286.286h1.073a.286.286 0 00.287-.286v-2.522a.286.286 0 00-.287-.286zM47.89 40.454h-3.413a.286.286 0 00-.286.286v2.522c0 .158.128.286.286.286h3.414a.286.286 0 00.286-.286V40.74a.286.286 0 00-.286-.286zM47.89 45.695h-3.413a.286.286 0 00-.286.286v2.522c0 .158.128.286.286.286h3.414a.286.286 0 00.286-.286V45.98a.286.286 0 00-.286-.286z"
          fill="#548A3E"
        />
        <Path
          d="M47.89 35.212h-1.073a.286.286 0 01.286.287v2.52a.286.286 0 01-.286.286h1.074a.286.286 0 00.286-.286v-2.52a.286.286 0 00-.286-.287zm0 5.24h-1.073a.286.286 0 01.286.286v2.521a.286.286 0 01-.286.287h1.074a.286.286 0 00.286-.287v-2.52a.286.286 0 00-.286-.287zm0 5.243h-1.073a.286.286 0 01.286.286v2.522a.286.286 0 01-.286.286h1.074a.286.286 0 00.286-.286v-2.522a.286.286 0 00-.286-.286zM22.199 24.73h-1.073a.287.287 0 01.286.286v2.522a.286.286 0 01-.286.286h1.073a.287.287 0 00.287-.286v-2.522a.286.286 0 00-.287-.286zm0 5.241h-1.073a.286.286 0 01.286.286v2.522a.286.286 0 01-.286.286h1.073a.287.287 0 00.287-.286v-2.522a.286.286 0 00-.287-.286zm0 10.48h-1.073a.286.286 0 01.286.287v2.521a.286.286 0 01-.286.287h1.073a.286.286 0 00.287-.287v-2.52a.286.286 0 00-.287-.287zm0-5.239h-1.073a.286.286 0 01.286.287v2.52a.286.286 0 01-.286.286h1.073a.286.286 0 00.287-.286v-2.52a.286.286 0 00-.287-.287zm0 10.483h-1.073a.286.286 0 01.286.286v2.522a.286.286 0 01-.286.286h1.073a.286.286 0 00.287-.286v-2.522a.286.286 0 00-.287-.286z"
          fill="#548A3E"
        />
        <Path
          d="M51.122 51.473H15.553a.537.537 0 110-1.073h35.569a.536.536 0 110 1.073zM43.117 19.662h-1.084v2.92h1.084v-2.92zM24.653 19.662H23.57v2.92h1.084v-2.92z"
          fill="#8B8893"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_120_1273">
          <Path
            fill="#fff"
            transform="translate(15.017 14.831)"
            d="M0 0H36.6418V36.6418H0z"
          />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export default BusinesLoanSvg