const weblinking = {
    config: {
        screens: {
            Home:'',
            LoginWeb:'/login',
            MobileHome:'/mhome',
        }
    },
};

export default weblinking;