import React from "react";
import { View, Text, TouchableOpacity, StyleSheet, Dimensions, useWindowDimensions, Linking } from "react-native";
import FooterLogo from "../../constants/svg/web/footerLogo";
import appfont from "../../constants/other/font";
import appColor from "../../constants/other/colors";
import AppStoreSvg from "../../constants/svg/web/appStore";
import PlayStoreSvg from "../../constants/svg/web/playStore";
import Icon from "react-native-vector-icons/AntDesign";


const footerContent = [
    {
        'id':1,
        'hname':'Company',
        'list':[
            {
                'id':1,
                'name':'About Us',
                'link':''
            },
            {
                'id':2,
                'name':'Blog',
                'link':''
            },
            {
                'id':3,
                'name':'Careers',
                'link':''
            },
            {
                'id':4,
                'name':'Contact Us',
                'link':''
            },
        ]
    },
    {
        'id':2,
        'hname':'Support',
        'list':[
            {
                'id':1,
                'name':'Help Center',
                'link':''
            },
            {
                'id':2,
                'name':'Safety Center',
                'link':''
            },
            {
                'id':3,
                'name':'Community Guidelines',
                'link':''
            },
        ]
    },
    {
        'id':3,
        'hname':'Legal',
        'list':[
            {
                'id':1,
                'name':'Cookies Policy',
                'link':''
            },
            {
                'id':2,
                'name':'Privacy Policy',
                'link':''
            },
            {
                'id':3,
                'name':'Terms of Service',
                'link':''
            },
        ]
    },

]





const Footer = () => {
    const { width } = useWindowDimensions();
const screenWidth = width;
const lagrgescreen = width > 600

const styles = StyleSheet.create({
    ftmain:{
        height: lagrgescreen ? 527 : 'auto',
        backgroundColor:'#011627',
        marginTop:50,
        paddingLeft:lagrgescreen ? 80 : 10,
        paddingRight:lagrgescreen ? 80 : 10,
        paddingTop:50,
        paddingBottom:10
    },
    ftop:{
        flexDirection:'row',
        flexWrap:'wrap'
    },
    ftop1:{
        width:lagrgescreen ? (screenWidth-160)/5 : width-20
    },
    hname:{
        fontFamily:appfont.semibold,
        fontSize:18,
        color:appColor.background,
        marginBottom:24
    },
    itmn:{
        fontFamily:appfont.regular,
        fontSize:14,
        color:appColor.background,
    }
})
    return (
        <View style={styles.ftmain}>
            <View style={styles.ftop}>
                <View style={styles.ftop1}>
                    <FooterLogo />
                </View>
                {
                    footerContent.map((item) => {
                        return(
                            <View style={styles.ftop1} key={item.id}>
                                <Text style={styles.hname}>{item.hname}</Text>
                                {
                                    item.list.map((itm) => {
                                        return(
                                            <TouchableOpacity key={itm.id} style={{marginBottom:12}}>
                                                <Text style={styles.itmn}>{itm.name}</Text>
                                            </TouchableOpacity>
                                        )
                                    })
                                }
                            </View>
                        )
                    })
                }

                <View style={styles.ftop1}>
                    <Text style={styles.hname}>Install App</Text>
                    <View>
                        <TouchableOpacity>
                            <PlayStoreSvg />
                        </TouchableOpacity>
                        <TouchableOpacity style={{marginTop:18}}>
                            <AppStoreSvg />
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
            <View style={{marginTop:10, flexDirection:'row', justifyContent:'space-between', alignItems:'center', flexWrap:'wrap'}}>
                <Text style={{color:appColor.background}}>
                    ©2024 Shotpe Tech Private Limited
                </Text>
                <View style={{flexDirection:'row', alignItems:'center'}}>
                    <TouchableOpacity onPress={() => Linking.openURL('https://www.instagram.com/shotpe_?igsh=dXZoeGFhemJ2bW1s')}>
                        <Icon name="instagram" size={20} color={appColor.background}/>
                    </TouchableOpacity>
                    <TouchableOpacity style={{marginLeft:10}} onPress={() => Linking.openURL('https://www.linkedin.com/company/shotpe/')}>
                        <Icon name="linkedin-square" size={20} color={appColor.background}/>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    )
}

export default Footer


