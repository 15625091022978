import React from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { View, Text, StyleSheet, Platform } from "react-native";
import appfont from "../constants/other/font";
import appColor from "../constants/other/colors";
import HomeScreen from "../screens/tab/Home";
import OfferScreen from "../screens/tab/Offer";
import CreditScore from "../screens/tab/CreditScore";
import ProfileScreen from "../screens/tab/Profile";
import BHomeSvg from "../constants/svg/BHome";
import BOfferSvg from "../constants/svg/boffer";
import BProfileSvg from "../constants/svg/bprofile";
import BCreditSvg from "../constants/svg/bcredit";



const Tab = createBottomTabNavigator()

const HomeTab = () => {
    return(
        <Tab.Navigator
            screenOptions={{
           headerShown:false,
           tabBarShowLabel:false,
           tabBarStyle:{
                height:88,
                borderTopLeftRadius:20,
                borderTopRightRadius:20,
                paddingTop:10,
                ...Platform.select({
                    android: { elevation: 5 },
                    ios: { 
                    shadowColor: '#000',
                    shadowOffset: { width: 0, height: 2 },
                    shadowOpacity: 0.25,
                    shadowRadius: 3.84,
                    },
                })
           }
        }}
        >
        <Tab.Screen 
            name="Home"
            component={HomeScreen}
            options={{
                tabBarIcon:({focused}) => (
                    <View style={focused ? styles.btmview : styles.btmview1}>
                        <BHomeSvg colour={appColor.primary}/>
                        <Text style={styles.tx}>Home</Text>
                    </View>
                )
            }}
        />
        <Tab.Screen 
            name="Offer"
            component={OfferScreen}
            options={{
                tabBarIcon:({focused}) => (
                    <View style={focused ? styles.btmview : styles.btmview1}>
                        <BOfferSvg colour={appColor.primary}/>
                        <Text style={styles.tx}>Offers</Text>
                    </View>
                )
            }}
        />
        <Tab.Screen 
            name="Credit"
            component={CreditScore}
            options={{
                tabBarIcon:({focused}) => (
                    <View style={focused ? styles.btmview : styles.btmview1}>
                        <View style={{marginBottom:4}}>
                        <BCreditSvg colour1={"#E0E0E0"} colour2={appColor.primary} />
                        </View>
                        <Text style={styles.tx}>Credit Score</Text>
                    </View>
                )
            }}
        />
        <Tab.Screen 
            name="Profile"
            component={ProfileScreen}
            options={{
                tabBarIcon:({focused}) => (
                    <View style={focused ? styles.btmview : styles.btmview1}>
                        <BProfileSvg colour={appColor.primary}/>
                        <Text style={styles.tx}>Account</Text>
                    </View>
                )
            }}
        />

        </Tab.Navigator>
    )
}

export default HomeTab

const styles = StyleSheet.create({
    btmview:{
        justifyContent:'center',
        alignItems:'center',
    },
    btmview1:{
        justifyContent:'center',
        alignItems:'center',
        opacity:0.6
    },
    tx:{
        fontFamily:appfont.semibold,
        color:appColor.primary,
        fontSize:12,
        marginTop:4
    },
    tx1:{
        fontFamily:appfont.semibold,
        color:'#B8B8D2',
        fontSize:12,
        marginTop:4
    }
})