import * as React from "react"
import Svg, { Path } from "react-native-svg"

function BusinessSvg(props) {
  return (
    <Svg
      width={41}
      height={37}
      viewBox="0 0 41 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path d="M32.884 5.529V37H6.851V0h20.525" fill="#F0F4F7" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.403.004v5.48h5.481l-5.48-5.48z"
        fill="#D2DCE9"
      />
      <Path
        d="M19.182 21.927h2.74v6.831h-2.74v-6.831zm5.481-4.114h2.74v10.945h-2.74V17.813zM32.884 19.185h-2.74v9.575h2.74v-9.575zM35.624 15.073h2.74V28.76h-2.74V15.073z"
        fill="#90CFF1"
      />
      <Path
        d="M10.286 3.43h1.37m-1.37 2.74h1.37m2.761-2.74h5.45m-5.449 2.74h5.45m.002 24.672h15.065"
        stroke="#8FB6D7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M18.497 18.512l6.848-4.872 5.49 2.13 5.972-6.165"
        stroke="#2C3AD9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M34.953 9.469H37v2.044"
        stroke="#2C3AD9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M15.757 8.92v19.183H40.41"
        stroke="#0C5286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M6.167 9.595h-.001c-2.128 0-3.853 1.734-3.853 3.872v10.068c0 2.139 1.725 3.873 3.853 3.873h.001c2.128 0 3.853-1.734 3.853-3.873V13.467c0-2.138-1.725-3.872-3.853-3.872z"
        fill="#F05542"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.624 17.956v2.254l-3.5 1.948C.429 22.606 0 23.441 0 24.345v3.063h12.332v-3.131c0-.862-.409-1.658-1.07-2.086l-3.555-1.999v-2.236H4.624z"
        fill="#2C3AD9"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.7 17.567v1.68a1.486 1.486 0 00.728 1.277l2.568 1.511c.146.086.383.23.51.343.51.442.816 1.128.826 1.866v3.163H0v-2.713c0-.194-.003-.507.022-.698a2.636 2.636 0 01.695-1.502c.201-.21.61-.456.862-.604l2.01-1.186a2.1 2.1 0 001.037-1.815v-1.324"
        fill="#F8B84E"
      />
      <Path
        d="M3.468 23.536c-.715 0-1.401.286-1.907.794a2.717 2.717 0 00-.526 3.078h4.868a2.72 2.72 0 00.057-2.2 2.712 2.712 0 00-1.46-1.466 2.687 2.687 0 00-1.032-.206zm2.698 2.71c0 .402.09.799.263 1.162h4.868a2.72 2.72 0 00.264-1.162 2.72 2.72 0 00-.79-1.917 2.7 2.7 0 00-1.909-.793c-.715 0-1.4.286-1.906.794a2.717 2.717 0 00-.79 1.916z"
        fill="#F9C061"
      />
      <Path
        d="M5.138 11.466c-.712 0-1.284.65-1.284 1.459v2.918c0 1.455 1.03 3.038 2.312 3.038 1.28 0 2.313-1.583 2.313-3.038v-1.75c0-.014-.002-.014-.003-.017 0-.014.003-.014.003-.017v-2.352l-.75-.302c-.459-.185-.15-.544-.53-.21-.208.184-.477.28-.754.27H5.138z"
        fill="#FCD062"
      />
      <Path
        d="M6.166 9.595c-.435 0-.851.073-1.24.206-.115.04-.11.529-.22.578-.119.055-.353-.332-.465-.266a3.873 3.873 0 00-1.809 2.436 3.065 3.065 0 002.192.918c.779 0 1.48-.291 2.019-.767a.435.435 0 01.587 0c.539.476 1.24.767 2.019.767.266 0 .52-.04.767-.105a3.852 3.852 0 00-3.85-3.767z"
        fill="#F05542"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.324 21.45l-.747.44c-.25.148-.658.394-.86.605-.387.413-.631.939-.696 1.5-.024.192-.02.505-.02.698v2.714h12.33v-3.165c-.009-.737-.314-1.423-.824-1.867a4.068 4.068 0 00-.51-.34l-.99-.582a3.85 3.85 0 01-3.841 3.63 3.85 3.85 0 01-3.842-3.633z"
        fill="#2C3AD9"
      />
      <Path d="M1.927 27.407V25.86m8.478 1.548V25.86" stroke="#27B2E7" />
    </Svg>
  )
}

export default BusinessSvg
