import React, {useState, useEffect, useRef} from 'react'
import { View, Text, StyleSheet, Dimensions, TouchableOpacity, TextInput, TouchableWithoutFeedback, Keyboard, useWindowDimensions, ActivityIndicator  } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context';
import appColor from '../../constants/other/colors'
import BackArrowSvg from '../../constants/svg/backarrow'
import LineHalfSvg from '../../constants/svg/linehalf'
import appfont from '../../constants/other/font'
import { useNavigation } from '@react-navigation/native'
import LineFull from '../../constants/svg/linefull'
import ServerData from '../../constants/other/serverData'
import axios from 'axios'
import AsyncStorage from '@react-native-async-storage/async-storage';


import { useDispatch } from 'react-redux';
import { userToken } from '../../toolkit/slice';
import * as SecureStore from 'expo-secure-store';
import LoadingModal from '../../components/loadingmodal';

const SignInScreen = ({route}) => {
    const navigation = useNavigation()
    const dispatch = useDispatch()
    const [modalVisible, setModalVisible] = useState(false);
    const [phone, setPhone] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setemail] = useState("")
    const [loginstep, setLoginStep] = useState(1)
    const firstInput = useRef()
    const secondInput = useRef()
    const thirdInput = useRef()
    const fourthInput = useRef()
    const [otpData, setOtpData] = useState({1:"", 2:"", 3:"", 4:""})
    const {fieldtype} = route.params
    const [signupOtpId, setSignupOtpId] = useState(null)
    const [loginOtpId, setLoginOtpId] = useState(null)

    const [token, setToken] = useState(null)
    const [loading, setLoading] = useState(false)
    

    const storeData = async (value) => {
        try {
          await AsyncStorage.setItem('token', value);
        } catch (e) {
          // saving error
        }
      };


    function handleback() {
        if (loginstep == 1){
            navigation.goBack()
        } else{
            setLoginStep(1)
        }
    }

    useEffect(() => {
        if (token !== null) {
            async function saveToken() {
                await SecureStore.setItemAsync('token', token);
                dispatch(userToken(token))
            }
            saveToken()
        }
    }, [token])

    function handleLogin() {
        if (loginstep == 1) {
            if (phone.length <10) {
                alert('Please enter correct mobile number')
            } else {
                setLoading(true)
                axios.post(`${ServerData.uri}/signin/`, {
                    signintype:1,
                    username:phone,
                    })
                    .then(function (response) {
                        setLoading(false)
                        if (response.data.code == 1){
                            setLoginOtpId(response.data.id)
                            setLoginStep(2)
                        }
                        if (response.data.code == 0) {
                            alert(response.data.message)
                        }
                    })
                    .catch(function (error) {
                        setLoading(false)
                    console.log(error);
                    });
            }
        } else{
            const finalOtp = otpData[1] + otpData[2] + otpData[3] + otpData[4]
            if (finalOtp.length < 4) {
                alert('Please enter valid otp')
            } else{
                setLoading(true)
                axios.post(`${ServerData.uri}/signin/`, {
                    signintype:2,
                    id:loginOtpId,
                    otp:finalOtp
                    })
                    .then(function (response) {
                        setLoading(false)
                        if (response.data.code == 2){
                            setToken(response.data.token)
                            storeData(response.data.token)
                            navigation.navigate('MobileHome')
                        }
                        if (response.data.code == 0) {
                            alert(response.data.message)
                        }
                    })
                    .catch(function (error) {
                        setLoading(false)
                    console.log(error);
                    });
            }
        }
    }

    function handleSignUp() {
        if (loginstep == 1) {
            if(firstName=="" || lastName == "" || phone == "" || email == "") {
                alert('Field can not be blank')
            }else{
                setLoading(true)
                axios.post(`${ServerData.uri}/signup/`, {
                    signuptype:1,
                    firstName:firstName,
                    lastName:lastName,
                    email:email,
                    username:phone,
                    })
                    .then(function (response) {
                        setLoading(false)
                        if (response.data.code == 1){
                            setSignupOtpId(response.data.id)
                            setLoginStep(2)
                        }
                        if (response.data.code == 0) {
                            alert(response.data.message)
                        }
                    })
                    .catch(function (error) {
                        setLoading(false)
                    console.log(error);
                    });
            }
        } else{
            const finalOtp = otpData[1] + otpData[2] + otpData[3] + otpData[4]
            if (finalOtp.length < 4) {
                alert('Please enter valid otp')
            } else {
                setLoading(true)
                axios.post(`${ServerData.uri}/signup/`, {
                    signuptype:2,
                    id:signupOtpId,
                    otp:finalOtp
                    })
                    .then(function (response) {
                        setLoading(false)
                        if (response.data.code == 2){
                            setToken(response.data.token)
                            storeData(response.data.token)
                            navigation.navigate('MobileHome')
                        }
                        if (response.data.code == 0) {
                            alert(response.data.message)
                        }
                    })
                    .catch(function (error) {
                        setLoading(false)
                    console.log(error);
                    });
            }
        }
    }

    return(
        <View style={styles.main}>
            <SafeAreaView style={styles.sfview}>
            <LoadingModal visible={modalVisible} />
                <View style={styles.tp}>
                <TouchableOpacity onPress={handleback}>
                    <BackArrowSvg />
                </TouchableOpacity>
                <View style={styles.line}>
                    {loginstep == 1?  <LineHalfSvg width = {screenWidth-48}/> : <LineFull width = {screenWidth-48}/>}
                </View>
                {
                    fieldtype == 1 ?
                    <Text style={styles.htxt}>{loginstep == 1 ? "Sign Up for a new account!" : "Phone number verification"}</Text>
                    :
                    <Text style={styles.htxt}>{loginstep == 1 ? "What's your phone number?" : "Phone number verification"}</Text>

                }
                <Text style={styles.ptxt}>{loginstep == 1 ?"We'll send you a verification code so make sure its your number": "Enter the code sent to your phone number"}</Text>


                {
                    loginstep == 1? 
                <>
                <View style={styles.inptview}>
                    <View style={styles.inptv2}>
                        <Text style={styles.fltxt}>Mobile Number</Text>
                        <TextInput 
                            value={phone}
                            style={styles.txtinpt}
                            onChangeText={(text) => setPhone(text)}
                            placeholder='Enter Mobile Number'
                            keyboardType='number-pad'
                            placeholderTextColor={appColor.txgray}
                            maxLength={10}
                        />
                    </View>
                </View> 

                {
                    fieldtype == 1 ?
                
                <>
                <View style={styles.inptview}>
                    <View style={styles.inptv2}>
                        <Text style={styles.fltxt}>Email</Text>
                        <TextInput 
                            value={email}
                            style={styles.txtinpt}
                            onChangeText={(text) => setemail(text)}
                            placeholder='examlpe@site.com'
                            placeholderTextColor={appColor.txgray}
                            autoCapitalize='none'

                        />
                    </View>
                </View>
                <View style={styles.inptview}>
                    <View style={styles.inptv2}>
                        <Text style={styles.fltxt}>First Name</Text>
                        <TextInput 
                            value={firstName}
                            style={styles.txtinpt}
                            onChangeText={(text) => setFirstName(text)}
                            placeholder='Your Last Name'
                            placeholderTextColor={appColor.txgray}
                        />
                    </View>
                </View>
                <View style={styles.inptview}>
                    <View style={styles.inptv2}>
                        <Text style={styles.fltxt}>Last Name</Text>
                        <TextInput 
                            value={lastName}
                            style={styles.txtinpt}
                            onChangeText={(text) => setLastName(text)}
                            placeholder='Your First Name'
                            placeholderTextColor={appColor.txgray}
                        />
                    </View>
                </View>
                </>:
                null
                }
                </>
                :
                <View style={styles.otpview}>
                    <TextInput 
                        style={otpData[1] == "" ? styles.txinput1 : styles.txinput}
                        maxLength={1}
                        keyboardType="number-pad"
                        ref={firstInput}
                        onChangeText={(text) => {
                            setOtpData({...otpData, 1:text})
                            text && secondInput.current.focus()
                        }}
                    />
                    <TextInput 
                        style={otpData[2] == "" ? styles.txinput1 : styles.txinput}
                        maxLength={1}
                        keyboardType="number-pad"
                        ref={secondInput}
                        onChangeText={(text) => {
                            setOtpData({...otpData, 2:text})
                            text ? thirdInput.current.focus() : firstInput.current.focus()
                        }}
                        onKeyPress={({ nativeEvent }) => {
                            if (nativeEvent.key === 'Backspace'){
                                    firstInput.current.focus()
                            }
                        }}
                    />
                    <TextInput 
                        style={otpData[3] == "" ? styles.txinput1 : styles.txinput}
                        maxLength={1}
                        keyboardType="number-pad"
                        ref={thirdInput}
                        onChangeText={(text) => {
                            setOtpData({...otpData, 3:text})
                            text ? fourthInput.current.focus() : secondInput.current.focus()
                        }}
                        onKeyPress={({ nativeEvent }) => {
                            if (nativeEvent.key === 'Backspace'){
                                secondInput.current.focus()
                            }
                        }}
                            
                    />
                    <TextInput 
                        style={otpData[4] == "" ? styles.txinput1 : styles.txinput}
                        maxLength={1}
                        keyboardType="number-pad"
                        ref={fourthInput}
                        onChangeText={(text) => {
                            setOtpData({...otpData, 4:text})
                            !text && thirdInput.current.focus()
                        }}
                        onKeyPress={({ nativeEvent }) => {
                            if (nativeEvent.key === 'Backspace'){
                                thirdInput.current.focus()
                            }
                        }}
                        
                        
                    />
                </View>
                }
                </View>

                {
                    fieldtype == 0 ?
                    <>
                    {
                        loading ?
                        <View style={styles.smbtn}>
                            <ActivityIndicator size='large' color={appColor.background} />
                        </View>
                        :
                        <TouchableOpacity style={styles.smbtn} onPress={() => handleLogin()}>
                    <Text style={styles.smtxt}>{loginstep == 1 ? "Get OTP" : "Verify"}</Text>
                    </TouchableOpacity>
                    }
                    </>
                    
                    :
                    <>
                        {
                            loading ?
                            <View style={styles.smbtn}>
                            <ActivityIndicator size='large' color={appColor.background} />
                        </View>
                        :
                        <TouchableOpacity style={styles.smbtn} onPress={() => handleSignUp()}>
                    <Text style={styles.smtxt}>{loginstep == 1 ? "Create Account" : "Verify"}</Text>
                    </TouchableOpacity>
                        }
                    </>
                }

            </SafeAreaView>
        </View>
    )
}

export default SignInScreen

const screenWidth = Dimensions.get('window').width
const width = Dimensions.get('window').width

const styles = StyleSheet.create({
    main:{
        flex:1,
        backgroundColor:appColor.background,
        justifyContent:'space-between'
    },
    sfview:{
        flex:1,
        justifyContent:'space-between',
    },
    tp:{
        paddingLeft:24,
        paddingRight:24
    },
    line:{
        marginTop:47
    },
    htxt:{
        fontFamily:appfont.bold,
        fontSize:18,
        color:appColor.txheadblack,
        marginTop:37
    },
    ptxt:{
        fontFamily:appfont.regular,
        fontSize:14,
        color:appColor.txgray,
        marginTop:16
    },
    inptview:{
        marginTop:22,
    },
    inptv2:{
        height:58,
        borderRadius:15,
        backgroundColor:appColor.gray,
        paddingLeft:29,
        paddingRight:29,
        width:screenWidth > 1200 ? 300 : screenWidth-48
    },
    fltxt:{
        fontFamily:appfont.semibold,
        fontSize:10,
        color:'#909090',
        marginTop:6
    },
    txtinpt:{
        height:30,
        fontFamily:appfont.bold,
        fontSize:14,
        color:appColor.txheadblack,
        width:screenWidth > 1200 ? 250: width-110,
    },
    smbtn:{
        height:49,
        backgroundColor:appColor.primary,
        borderRadius:20,
        justifyContent:'center',
        alignItems:'center',
        marginLeft:24,
        marginRight:24,
        marginBottom:80
        },
    smtxt:{
        fontFamily:appfont.bold,
        fontSize:15,
        color:appColor.background,
    },
    otpview:{
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        marginTop:50
    },
    txinput:{
        height:screenWidth > 1200 ? 80 : screenWidth/6,
        width:screenWidth > 1200 ? 80 : screenWidth/6,
        backgroundColor:appColor.primary,
        borderRadius:15,
        textAlign:'center',
        fontFamily:appfont.bold,
        fontSize:18,
        color:appColor.background
    },
    txinput1:{
        height:screenWidth > 1200 ? 80 : screenWidth/6,
        width:screenWidth > 1200 ? 80 : screenWidth/6,
        backgroundColor:'#F3F3F3',
        borderRadius:15,
        textAlign:'center',
        fontFamily:appfont.bold,
        fontSize:18,
        color:appColor.background
    }

})