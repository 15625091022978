import { View } from 'react-native';
import LottieView from 'lottie-react-native';

export default function LoadingAnimation() {
  return (
    <View>
      <LottieView 
        source={require('../constants/animation/loading.json')} 
        autoPlay 
        loop 
        style={{
          width: 150,
          height: 150,
        }}
      />
    </View>
  );
}