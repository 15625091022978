import React, {useState, useEffect} from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import HomeTab from "./BottomTab";
import Splash1 from "../screens/splash/Splash1";
import Splash2 from "../screens/splash/Splash2";
import Splash3 from "../screens/splash/Splash3";
import SignInScreen from "../screens/authentications/SignIn";

import * as SecureStore from 'expo-secure-store';
import { useDispatch, useSelector } from "react-redux";
import { userToken } from "../toolkit/slice";
import PersonalLoan from "../screens/loanapplication.js/PersonalLoan";
import WebHome from "../screens/web/WebHome";
import IntHome from "../screens/web/webint/IntHome";
import LoginWeb from "../screens/web/webLogin";
import HomeScreen from "../screens/tab/Home";





const Stack = createNativeStackNavigator()


const HomeScreeNavigator = () => {
   const dispatch = useDispatch()
   const tknsts = useSelector((state) => state.appredux.userToken)

   useEffect(() => {
      async function getToken() {
          const key = await SecureStore.getItemAsync('token')
          if (key !== null) {
            dispatch(userToken(key))
          }
      }
      getToken()
  }, [])

     return (
         <Stack.Navigator
         screenOptions={{
            headerShown:false,
            tabBarShowLabel:false,
         }}
         
         >   
            <Stack.Screen
                 name="Home"
                 component={IntHome}
              /> 
               <Stack.Screen
                 name="LoginWeb"
                 component={LoginWeb}
              /> 
         
            <Stack.Screen
                 name="Splash1"
                 component={Splash1}
              /> 
              <Stack.Screen
                 name="Splash2"
                 component={Splash2}
              /> 
              <Stack.Screen
                 name="Splash3"
                 component={Splash3}
              /> 
              <Stack.Screen
                 name="Signin"
                 component={SignInScreen}
              /> 
              <Stack.Screen
                 name="PersonalScreen"
                 component={PersonalLoan}
              /> 
              <Stack.Screen
                 name="MobileHome"
                 component={HomeScreen}
              /> 
         </Stack.Navigator>
     )
 }
 
 export default HomeScreeNavigator