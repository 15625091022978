import React, {useState, useEffect} from "react";
import { View, Text, TouchableOpacity, StyleSheet, Linking } from "react-native";
import appfont from "../../constants/other/font";
import appColor from "../../constants/other/colors";
import ButtonWeb from "./WebButton";
import LogoSvg from "../../constants/svg/logo";
import { useNavigation } from "@react-navigation/native";
import { useSelector } from "react-redux";
import AsyncStorage from '@react-native-async-storage/async-storage';


const hdrdata = [
    {
        'id':1,
        'name':'Home',
        'screen':'',
        'type':1,
        
    },
    {
        'id':2,
        'name':'Loans',
        'screen':'https://shotpe.roopya.money/customer/personal_loan/fc8a5d8677e8971511098a31ae1decd4a2b7478c422e7f58c10858f3626eb265/9efc3f5e2a6fc72789299e5bbcc45a0c3c85fc567b3f2103c7180a4a9df4dff0?jrnyTyp=J2',
        'type':3,
    },
    {
        'id':4,
        'name':'Credit Score',
        'screen':'https://shotpe.roopya.money/customer/personal_loan/278b940af5bd9da0d02f23db2b60b97ef90e0a1672bc92e6f2ca26f8cec90bfc/54e5f863be9bf828705ae4ff7d417dbece5755d17ffa5ecd3204424443b74aed',
        'type':1,
    },
    {
        'id':5,
        'name':'Offers',
        'screen':'https://shotpe.roopya.money/customer/personal_loan/fc8a5d8677e8971511098a31ae1decd4a2b7478c422e7f58c10858f3626eb265/9efc3f5e2a6fc72789299e5bbcc45a0c3c85fc567b3f2103c7180a4a9df4dff0?jrnyTyp=J2',
        'type':1,
    },
    {
        'id':6,
        'name':'Contact Us',
        'screen':'',
        'type':1,
    }
]

function handleTouch() {
    alert('Clicked')
}

const WebHeader = ({onpress1}) => {
    const navigation = useNavigation()
    const [activeTab, setActiveTab] = useState(1)
    const [tknsts, setTokenSts] = useState(null) 

    const getData = async () => {
        try {
          const value = await AsyncStorage.getItem('token');
          if (value !== null) {
            setTokenSts(value)
          }
        } catch (e) {
          // error reading value
        }
      };

      useEffect(() => {
        getData()
      },[])

      const LogOutFuntion = async () => {
        try {
            await AsyncStorage.removeItem('token');
            navigation.navigate('Home')
          } catch (e) {
            // error reading value
          }
      }


    function handlePress(id, link){
        setActiveTab(id)
        window.location.href = link;
    }
    return (
      <View style={styles.hd}>
        <LogoSvg height={65.16} width={88}/>
        <View style={styles.tvm}>
            <View style={styles.txv}>
                {
                    hdrdata.map((item) => {
                        return(
                            <TouchableOpacity key={item.id} style={{justifyContent:'center', marginLeft:25}} onPress={() => handlePress(item.id, item.screen)}>
                            <View style={activeTab == item.id ? styles.line : styles.line1}></View>
                            <Text style={styles.txt1}>{item.name}</Text>
                            </TouchableOpacity>
                        )
                    })
                }
            </View>
            <View>
            {
                tknsts ? 
                <ButtonWeb height={47} width={112} type={2} text={'Log Out'} onPress={() => LogOutFuntion()}/>
                :
                <View style={styles.btngrp}>
                <ButtonWeb height={47} width={112} type={2} text={'Log In'} onPress={() => navigation.navigate('Signin', {fieldtype : 0})}/>
                <ButtonWeb height={47} width={177} type={1} text={'Get Started'} onPress={() => navigation.navigate('Signin', {fieldtype : 1})}/>
                </View>
            }
            </View>
        </View>
    </View>
    );
  };

export default WebHeader;

const styles = StyleSheet.create({
    txt1:{
        fontFamily:appfont.bold,
        fontSize:14,
        color:appColor.txheadblack,
    },
    hd:{
        height:100,
        backgroundColor:appColor.background,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.3,
        shadowRadius: 4,
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        paddingLeft:80,
        paddingRight:80
    },
    btngrp:{
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:"center",
        width:320
    },
    txv:{
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'space-between',
        marginRight:30,

    },
    tvm:{
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
    },
    line:{
        height:5,
        width:30,
        borderRadius:10,
        backgroundColor:appColor.primary,
    },
    line1:{
        height:5,
        width:30,
    }
    

})

