import React, {useState} from "react";
import { View, Text, Dimensions, useWindowDimensions } from "react-native";
import WebHome from "../WebHome";
import HomeScreen from "../../tab/Home";


const IntHome = () => {
    const { width } = useWindowDimensions();
    return (
        <WebHome />
    )
}

export default IntHome