import React, {useState, useEffect} from 'react'
import { View, Text, StyleSheet, ScrollView } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context';
import CustomTextInput from '../../components/TextInput'
import appColor from '../../constants/other/colors';
import CustomTouchable from '../../components/TouchableWFeed';
import CustomLoanHeader from '../../components/Loanheader';
import Employmenttype from '../../components/loanapplication/Employmentype';
import CustomButton from '../../components/customButton';
import { useSelector } from 'react-redux';
import { userData } from '../../toolkit/slice';

const PersonalLoan = ({route}) => {
    const userdata = useSelector((state) => state.appredux.userData)
    const [text, setText] = useState(userdata.firstName);
    const [loanStep, setLoanStep] = useState(1)
    const [emplumentype, setEmploymentType] = useState(null)
    const {loanname} = route.params


    const handlePress = (item) => {
        setEmploymentType(item.id);
      };

    function handlesubmit() {
        if (text == '' || emplumentype == null){
            alert('Please provide valid details')
        }
        else{
            alert('No bank integration found')
        }
    }


    return(
        <CustomTouchable>
        <View style={styles.main}>
        <SafeAreaView style={styles.sfview}>
            <CustomLoanHeader title={loanname} loanStep={loanStep}/>
            <ScrollView style={styles.scrl} showsVerticalScrollIndicator={false}>
            <CustomTextInput 
                placeholder={'Enter Name'}
                value={text}
                onChangeText={setText}
                name={'First Name'}
                maxLength={50}
            />
            <Employmenttype emptype={emplumentype} onPress={handlePress}/>
            <CustomButton name={'Proceed'} onPress={handlesubmit}/>
            <View style={{height:80}}></View>
            </ScrollView>
        </SafeAreaView>
        </View>
        </CustomTouchable>
    )
}

export default PersonalLoan

const styles = StyleSheet.create({
    main:{
        flex:1,
        backgroundColor:appColor.background
    },
    sfview:{
        flex:1,
        marginLeft:24,
        marginRight:24
    },
    scrl:{
        paddingTop:25
    }
})