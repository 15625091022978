import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userToken:null,
    userData:{},
}

export const appreduxSlice = createSlice({
    name:'appredux',
    initialState,
    reducers:{
        userToken: (state, action) => {
            state.userToken = (action.payload)
        },
        userData: (state, action) => {
            state.userData = (action.payload)
        },
    }
})

export const {userToken, userData} = appreduxSlice.actions
export default appreduxSlice.reducer