import React, {useState, useEffect, useRef, } from 'react'
import { View, Text, StyleSheet, TouchableOpacity, ScrollView, Dimensions, BackHandler, Alert, useWindowDimensions } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context';
import appColor from '../../constants/other/colors'
import SideMenuSvg from '../../constants/svg/sidemenu';
import BellSvg from '../../constants/svg/bell';
import appfont from '../../constants/other/font';
import PersonalLoanSvg from '../../constants/svg/PersonalLoan';
import MicroLoanSvg from '../../constants/svg/MicroLoan';
import BusinesLoanSvg from '../../constants/svg/BusinessLoan';
import HomeLoanSvg from '../../constants/svg/HomeLoan';
import Card1Svg from '../../constants/svg/card1';
import Card2Svg from '../../constants/svg/Card2';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import OfferSvg from '../../constants/svg/offer';
import * as Contacts from 'expo-contacts';
import { useDispatch, useSelector } from "react-redux";
import { userToken, userData } from '../../toolkit/slice';
import axios from 'axios';
import ServerData from '../../constants/other/serverData';
import BikeLoanSvg from '../../constants/svg/BikeLoanSvg';
import CarLoanSvg from '../../constants/svg/CarLoanSvg';
import { StatusBar } from 'expo-status-bar';
import WebHeader from '../../components/WebComponent/Header';
import MobileWebHeader from '../../components/WebComponent/MobileWebHeader';



const width = Dimensions.get('window').width

const HomeScreen = () => {
    const { width } = useWindowDimensions();
    const scrollViewRef = useRef();
    const dispatch = useDispatch()
    const [currentPage, setCurrentPage] = useState(0);
    const navigation = useNavigation()
    const tknsts = useSelector((state) => state.appredux.userToken)
    const userdata = useSelector((state) => state.appredux.userData)
    const [userFirstName, setUserFName] = useState(null)


    const handleScroll = (event) => {
        const position = event.nativeEvent.contentOffset.x;
        const page = Math.round(position / PAGE_WIDTH);
        setCurrentPage(page);
      };
    
      const PAGE_WIDTH = width;
      const PAGE_COUNT = 2;

      useEffect(() => {
        axios.post(`${ServerData.uri}/getuserdata/`, {
            token:tknsts,
            })
            .then(function (response) {
                if (response.data.code == 1) {
                    dispatch(userData(response.data.userData))
                }
            })
            .catch(function (error) {
            console.log(error);
            });
      },[])

      useFocusEffect(
        React.useCallback(() => {
            const backAction = () => {
                Alert.alert('Hold on!', 'Are you sure you want to exit?', [
                  {
                    text: 'Cancel',
                    onPress: () => null,
                    style: 'cancel',
                  },
                  { text: 'YES', onPress: () => BackHandler.exitApp() },
                ]);
                return true;
              };
          
              const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);
          
              return () => backHandler.remove();
        }, [])
      );
    
    return(
        <View style={styles.main}>
            <StatusBar style='dark' />
            <SafeAreaView style={styles.sfview}>
            <ScrollView style={styles.scrl} showsVerticalScrollIndicator={false}>
            {
                width > 1200 ?
                <WebHeader />
                :
                <MobileWebHeader />
            }
                <ScrollView horizontal={true} showsHorizontalScrollIndicator={false} pagingEnabled={true} style={styles.vrscrl} onScroll={handleScroll}>
                    <TouchableOpacity >
                        <Card1Svg />
                    </TouchableOpacity>
                    <TouchableOpacity>
                        <Card2Svg />
                    </TouchableOpacity>
                </ScrollView>
                <View style={styles.dots}>
                    <View style={currentPage == 0? styles.dot1 : styles.dot2}></View><View  style={currentPage == 1 ? styles.dot1 : styles.dot2}></View>
                </View>
                <View style={styles.sec1}>
                    <Text style={styles.lnh}>Loan</Text>
                    <View style={styles.lnsec}>
                        <TouchableOpacity style={styles.ln} onPress={() => window.location.href = 'https://shotpe.roopya.money/customer/personal_loan/fc8a5d8677e8971511098a31ae1decd4a2b7478c422e7f58c10858f3626eb265/9efc3f5e2a6fc72789299e5bbcc45a0c3c85fc567b3f2103c7180a4a9df4dff0?jrnyTyp=J2'}>
                            <PersonalLoanSvg height={width > 1200 ? 70 : width/6} width={width > 1200 ? 70 : width/6} />
                            <Text style={styles.lntxt}>Personal Loan</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.ln3} > 
                            <MicroLoanSvg height={width > 1200 ? 70 : width/6} width={width > 1200 ? 70 : width/6} />
                            <Text style={styles.lntxt}>Micro Loan</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.ln3} >
                        <BusinesLoanSvg height={width > 1200 ? 70 : width/6} width={width > 1200 ? 70 : width/6} />
                            <Text style={styles.lntxt}>Business Loan</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.ln3} >
                        <HomeLoanSvg height={width > 1200 ? 70 : width/6} width={width > 1200 ? 70 : width/6} />
                            <Text style={styles.lntxt}>Home Loan</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={styles.sec2}>
                    <Text style={styles.lnh}>Vehicle Loan</Text>
                    <View style={styles.lnsec}>
                        <TouchableOpacity style={styles.ln3} >
                            <CarLoanSvg height={width > 1200 ? 70 : width/6} width={width > 1200 ? 70 : width/6} />
                            <Text style={styles.lntxt}>Used Car Loan</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.ln3} > 
                            <CarLoanSvg height={width > 1200 ? 70 : width/6} width={width > 1200 ? 70 : width/6} />
                            <Text style={styles.lntxt}>New Car Loan</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.ln3} >
                        <BikeLoanSvg height={width > 1200 ? 70 : width/6} width={width > 1200 ? 70 : width/6} />
                            <Text style={styles.lntxt}>Bike Loan</Text>
                        </TouchableOpacity>
                        <View style={styles.ln1}>
                        <HomeLoanSvg height={width > 1200 ? 70 : width/6} width={width > 1200 ? 70 : width/6} />
                            <Text style={styles.lntxt}></Text>
                        </View>
                    </View>
                </View>
                <TouchableOpacity style={styles.ofbnr} onPress={() => window.location.href = 'https://shotpe.roopya.money/customer/personal_loan/278b940af5bd9da0d02f23db2b60b97ef90e0a1672bc92e6f2ca26f8cec90bfc/54e5f863be9bf828705ae4ff7d417dbece5755d17ffa5ecd3204424443b74aed'}>
                    <View style={styles.ofbnrtxt}>
                        <Text style={styles.oftxt1}>Offers for you!</Text>
                        <Text style={styles.oftxt2}>Check Your Credit Score for Free</Text>
                    </View>
                    <OfferSvg />
                </TouchableOpacity>
            </ScrollView>
            </SafeAreaView>
        </View>
    )
}

export default HomeScreen

const styles = StyleSheet.create({
    main:{
        flex:1,
        backgroundColor:appColor.background
    },
    sfview:{
        flex:1
    },
    top:{
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        paddingLeft:24,
        paddingRight:24,
        marginTop:20
    },
    wltxt:{
        fontFamily:appfont.bold,
        color:appColor.txheadblack,
        fontSize:25,
    },
    lnsec:{
        flexDirection:'row',
        justifyContent:'space-between',
        marginTop:10
    },
    lntxt:{
        fontFamily:appfont.semibold,
        fontSize:11,
        color:appColor.txt,
        marginTop:7
    },
    ln:{
        alignItems:'center',
        justifyContent:'center'
    },
    ln3:{
        alignItems:'center',
        justifyContent:'center',
        opacity:0.5
    },
    ln1:{
        alignItems:'center',
        justifyContent:'center',
        opacity:0
    },
    sec1:{
        paddingLeft:24,
        paddingRight:24,
        marginTop:8
    },
    sec2:{
        paddingLeft:24,
        paddingRight:24,
        marginTop:29
    },
    lnh:{
        fontFamily:appfont.bold,
        fontSize:18,
        color:appColor.txheadblack,
    },
    vrscrl:{
        marginTop:10
    },
    dots:{
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center'
    },
    dot1:{
        height:5,
        width:26,
        backgroundColor:appColor.primary,
        borderRadius:10,
        marginLeft:3,
        marginRight:3
    },
    dot2:{
        height:6,
        width:5,
        borderWidth:1,
        borderColor:appColor.primary,
        borderRadius:10,
        marginLeft:3,
        marginRight:3
    },
    ofbnr:{
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        height:109,
        marginTop:41,
        backgroundColor:'rgba(255, 200, 165, 0.33);',
        paddingLeft:50,
        paddingRight:50
    },
    ofbnrtxt:{
        width:width/2
    },
    oftxt1:{
        fontFamily:appfont.regular,
        fontSize:12,
        color:'#F37421'
    },
    oftxt2:{
        fontFamily:appfont.semibold,
        fontSize:15,
        color:'#21254B'
    }
})