import React from 'react';
import { StyleSheet, View, TouchableOpacity, Dimensions, Text } from 'react-native';
import LineFull from '../constants/svg/linefull';
import BackArrowSvg from '../constants/svg/backarrow';
import appfont from '../constants/other/font';
import appColor from '../constants/other/colors';
import { useNavigation } from '@react-navigation/native';

export default function CustomLoanHeader({ loanStep, title }) {
    const navigation = useNavigation()

  return (
    <View style={styles.hmain}>
        <View style={styles.htp}>
            <TouchableOpacity onPress={() => navigation.goBack()}>
                <BackArrowSvg />
            </TouchableOpacity>
            <Text style={styles.htitle}>{title}</Text>
            <View style={{width:10, height:17}}></View>
        </View>
        <View style={styles.elview}>
            <Text style={styles.txt1}>Learn more about eligibility </Text>
            <TouchableOpacity>
                <Text style={styles.txt2}>here</Text>
            </TouchableOpacity>
        </View>
        <View style={{marginTop:25, marginBottom:25}}>
        <LineFull width={screenWidth-48}/>
        </View>
        <Text style={styles.pltxt}>Apply For {title}</Text>
    </View>
  );
}

const screenWidth = Dimensions.get('window').width

const styles = StyleSheet.create({
    hmain:{
        width:screenWidth-48
    },
    htp:{
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'space-between'
    },
    htitle:{
        fontFamily:appfont.bold,
        fontSize:18,
        color:appColor.txheadblack,
    },
    elview:{
        flexDirection:'row',
        alignItems:'center',
        marginTop:25
    },
    txt1:{
        fontFamily:appfont.regular,
        fontSize:15,
        color:appColor.txgray
    },
    txt2:{
        fontFamily:appfont.regular,
        fontSize:15,
        color:appColor.primary
    },
    pltxt:{
        fontFamily:appfont.bold,
        fontSize:18,
        color:appColor.txheadblack
    }
});