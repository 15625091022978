import React from "react";
import { View, Text, useWindowDimensions, StyleSheet } from "react-native";
import appColor from "../../constants/other/colors";
import LogoSvg from "../../constants/svg/logo";

const LoginWeb = () => {
    const {width , height} = useWindowDimensions()
    const styles = StyleSheet.create({
        main:{
            flex:1
        },
        background:{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#CDD9FE',
        },
        container:{
            flex:1
        },
        bgf:{
            height:height,
            width:width/2,
            backgroundColor:appColor.background
        },
        lg:{
            height:65.16,
            width:88,
            marginTop:50,
            marginLeft:80
        }
    })
    return (
        <View style={styles.main}>
            <View style={styles.background}>
                <View style={styles.bgf}>
                    <View style={styles.lg}>
                    <LogoSvg />
                    </View>
                </View>
                <View style={styles.bgr}></View>
            </View>
            <View style={styles.container}>
            </View>
        </View>
    )
}

export default LoginWeb