import * as React from "react"
import Svg, { Circle, G, Path, Defs, ClipPath } from "react-native-svg"

function CarLoanSvg(props) {
  return (
    <Svg
      width={props.width}
      height={props.height}
      viewBox="0 0 67 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Circle
        cx={33.15}
        cy={33.15}
        r={33.15}
        fill="#F2F2FF"
        fillOpacity={0.55}
      />
      <G clipPath="url(#clip0_120_1392)">
        <Path
          d="M50.933 43.846l-.004.825a2.147 2.147 0 01-2.146 2.14H18.318a2.147 2.147 0 01-1.888-1.123l-.803-1.474a2.155 2.155 0 01-.259-1.102c0-.03 0-.062.005-.094l.244-3.051.232-2.916a2.155 2.155 0 01.228-.807l2.28-4.474a2.153 2.153 0 011.912-1.173h14.145c.52 0 1.023.188 1.414.53l6.358 5.559.298.071 6.213 1.464a2.147 2.147 0 011.631 1.759l.49 3.132.115.734z"
          fill="#548A3E"
        />
        <Path
          d="M50.819 43.112l-.49-3.132a2.147 2.147 0 00-1.63-1.76l-6.215-1.464-.297-.071-6.358-5.559a2.147 2.147 0 00-1.414-.53h-2.147c.52 0 1.023.188 1.414.53l6.358 5.559.298.071 6.213 1.464a2.147 2.147 0 011.63 1.76l.49 3.132.114.734-.002.826a2.147 2.147 0 01-2.147 2.14h2.147a2.147 2.147 0 002.146-2.14l.004-.827-.114-.733z"
          fill="#548A3E"
        />
        <Path
          d="M42.484 36.756H15.89c.039-.178.1-.35.183-.512l2.28-4.474a2.152 2.152 0 011.915-1.173h14.145c.52 0 1.023.188 1.414.53l6.358 5.559.298.07z"
          fill="#52BBE9"
        />
        <Path
          d="M42.186 36.686l-6.358-5.558a2.146 2.146 0 00-1.414-.531h-2.146c.52 0 1.022.188 1.414.53l6.357 5.559.298.071h2.147l-.298-.071z"
          fill="#1CABE1"
        />
        <Path
          d="M28.387 36.756h-1.073l-.633-6.104a.506.506 0 010-.055h1.073l.633 6.104a.506.506 0 010 .055z"
          fill="#A8D9F5"
        />
        <Path
          d="M43.13 50.933a4.121 4.121 0 11-.002-8.242 4.121 4.121 0 01.001 8.242z"
          fill="#787681"
        />
        <Path
          d="M46.042 43.9a4.092 4.092 0 00-2.913-1.207 4.2 4.2 0 00-.652.051 4.12 4.12 0 010 8.138 4.12 4.12 0 003.565-6.982z"
          fill="#57565C"
        />
        <Path
          d="M43.13 48.425a1.61 1.61 0 11.002-3.22 1.61 1.61 0 01-.003 3.22z"
          fill="#E1E1E3"
        />
        <Path
          d="M44.27 45.673a1.608 1.608 0 00-1.674-.379 1.61 1.61 0 010 3.038 1.61 1.61 0 001.673-2.659z"
          fill="#C8C5CC"
        />
        <Path
          d="M23.783 50.933a4.119 4.119 0 11.001-8.237 4.119 4.119 0 010 8.237z"
          fill="#787681"
        />
        <Path
          d="M50.819 43.112h-1.353a1.073 1.073 0 01-1.073-1.073v-1.002a1.073 1.073 0 011.073-1.074h.844l.021.019.487 3.13z"
          fill="#2C3AD9"
        />
        <Path
          d="M50.329 39.98l-.021-.019h-.842c-.07 0-.14.007-.208.021l.49 3.13h1.073l-.492-3.132zM17.502 41.036v1.002a1.074 1.074 0 01-1.074 1.074h-1.06c0-.031 0-.063.005-.094l.244-3.05.813-.007a1.074 1.074 0 011.072 1.075z"
          fill="#2C3AD9"
        />
        <Path
          d="M31.951 39.43h-1.574a.536.536 0 110-1.074h1.574a.536.536 0 110 1.074zm-5.373 0h-1.575a.537.537 0 110-1.074h1.575a.537.537 0 110 1.074zm-2.795 8.995a1.612 1.612 0 11-.004-3.223 1.612 1.612 0 01.004 3.223z"
          fill="#E1E1E3"
        />
        <Path
          d="M26.72 43.9a4.092 4.092 0 00-2.912-1.207 4.2 4.2 0 00-.652.051 4.12 4.12 0 010 8.138A4.12 4.12 0 0026.72 43.9z"
          fill="#57565C"
        />
        <Path
          d="M24.948 45.673a1.608 1.608 0 00-1.674-.379 1.61 1.61 0 010 3.038 1.61 1.61 0 001.674-2.659z"
          fill="#C8C5CC"
        />
        <Path
          d="M27.946 31.63a5.385 5.385 0 113.807-1.575 5.35 5.35 0 01-3.807 1.574z"
          fill="#2C3AD9"
        />
        <Path
          d="M31.753 22.437a5.35 5.35 0 00-3.807-1.575c-.3 0-.599.024-.895.072a5.385 5.385 0 010 10.62 5.385 5.385 0 004.702-9.118z"
          fill="#2C3AD9"
        />
        <Path
          d="M27.946 32.166a5.92 5.92 0 114.187-1.735 5.881 5.881 0 01-4.187 1.735zm0-10.77a4.85 4.85 0 103.428 1.42 4.816 4.816 0 00-3.428-1.42z"
          fill="#2C3AD9"
        />
        <Path
          d="M27.354 27.274H26a.537.537 0 010-1.073h1.352a1.127 1.127 0 000-2.254h-.97a.537.537 0 110-1.073h.97a2.2 2.2 0 010 4.4z"
          fill="#fff"
        />
        <Path
          d="M29.93 23.947H26a.536.536 0 110-1.073h3.929a.536.536 0 110 1.073zm0 1.637H26a.536.536 0 110-1.074h3.929a.536.536 0 110 1.074zm-.976 4.579a.532.532 0 01-.373-.152l-2.977-2.892a.537.537 0 01.748-.767l2.977 2.892a.536.536 0 01-.375.921v-.002z"
          fill="#fff"
        />
        <Path
          d="M50.933 51.47H15.367a.536.536 0 110-1.073h35.566a.536.536 0 110 1.073z"
          fill="#8B8893"
        />
        <Path
          d="M38.793 28.311a.537.537 0 01-.345-.126l-2.058-1.732a.538.538 0 01-.017-.807l2.043-1.865a.537.537 0 01.724.793l-1.591 1.452 1.59 1.338a.537.537 0 01-.346.947z"
          fill="#19AC66"
        />
        <Path
          d="M46.64 34.072a.537.537 0 01-.537-.537v-5.274a1.683 1.683 0 00-1.682-1.682h-7.686a.537.537 0 010-1.073h7.686a2.758 2.758 0 012.755 2.755v5.274a.537.537 0 01-.537.537z"
          fill="#19AC66"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_120_1392">
          <Path
            fill="#fff"
            transform="translate(14.83 14.83)"
            d="M0 0H36.6395V36.6395H0z"
          />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export default CarLoanSvg
