import React, {useState, useEffect} from "react";
import { View, Text, TouchableOpacity, StyleSheet, useWindowDimensions } from "react-native";
import appfont from "../../constants/other/font";
import appColor from "../../constants/other/colors";
import ButtonWeb from "./WebButton";
import LogoSvg from "../../constants/svg/logo";
import { useNavigation } from "@react-navigation/native";
import Icon from "react-native-vector-icons/Feather";
import { useSelector } from "react-redux";
import AsyncStorage from '@react-native-async-storage/async-storage';

const hdrdata = [
    {
        'id':1,
        'name':'Home',
        'screen':'',
        'type':1,
    },
    {
        'id':2,
        'name':'Loans',
        'screen':'',
        'type':3,
    },
    {
        'id':4,
        'name':'Credit Score',
        'screen':'onpress1',
        'type':1,
    },
    {
        'id':5,
        'name':'Offers',
        'screen':'',
        'type':1,
    },
    {
        'id':6,
        'name':'Contact Us',
        'screen':'',
        'type':1,
    }
]

function handleTouch() {
    alert('Clicked')
}

const MobileWebHeader = ({onpress1}) => {
    const navigation = useNavigation()
    const [activeTab, setActiveTab] = useState(1)
    const {width} = useWindowDimensions()
    const [tknsts, setTokenSts] = useState(null) 

    const getData = async () => {
        try {
          const value = await AsyncStorage.getItem('token');
          if (value !== null) {
            setTokenSts(value)
          }
        } catch (e) {
          // error reading value
        }
      };

      useEffect(() => {
        getData()
      },[])

      const LogOutFuntion = async () => {
        try {
            await AsyncStorage.removeItem('token');
            navigation.navigate('Home')
          } catch (e) {
            // error reading value
          }
      }


    const styles = StyleSheet.create({
        txt1:{
            fontFamily:appfont.bold,
            fontSize:14,
            color:appColor.txheadblack,
        },
        hd:{
            height:100,
            backgroundColor:appColor.background,
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.3,
            shadowRadius: 4,
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center',
            paddingLeft:24,
            paddingRight:24
        },
        btngrp:{
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:"center",
            width:320
        },
        txv:{
            flexDirection:'row',
            alignItems:'center',
            justifyContent:'space-between',
            marginRight:30
    
        },
        tvm:{
            justifyContent:'center',
            alignItems:'center',
            flexDirection:'row'
        },
        line:{
            height:5,
            width:30,
            borderRadius:10,
            backgroundColor:appColor.primary,
        },
        line1:{
            height:5,
            width:30,
        }
        
    
    })
    return (
      <View style={styles.hd}>
        <LogoSvg height={65.16} width={88}/>
        {
            tknsts ? 
            <ButtonWeb height={30} width={70} type={1} text={'Log Out'} onPress={() => LogOutFuntion()}/>
            :
            <View style={{flexDirection:'row'}}>
        <ButtonWeb height={30} width={60} type={2} text={'Log In'} onPress={() => navigation.navigate('Signin', {fieldtype : 0})}/>
        <View style={{width:10}}></View>
        <ButtonWeb height={30} width={70} type={1} text={'Sign Up'} onPress={() => navigation.navigate('Signin', {fieldtype : 1})}/>

        </View>
        }
    </View>
    );
  };

export default MobileWebHeader;



