import React, {useCallback, useEffect} from 'react'
import { Provider } from 'react-redux';
import { store } from './toolkit/store';
import { NavigationContainer } from '@react-navigation/native';
import HomeScreeNavigator from './route/HomeStack';
import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import weblinking from './screens/web/WebLink';


SplashScreen.preventAutoHideAsync();

export default function App() {
  const [fontsLoaded] = useFonts({
      'Montserrat-Regular': require('./constants/fonts/Montserrat-Regular.ttf'),
      'Montserrat-SemiBold': require('./constants/fonts/Montserrat-SemiBold.ttf'),
      'Montserrat-Thin': require('./constants/fonts/Montserrat-Thin.ttf'),
      'Montserrat-Bold': require('./constants/fonts/Montserrat-Bold.ttf'),
  });

  useEffect(() => {
    function hdd() {
      if (fontsLoaded) {
        setTimeout(async() => {
          await SplashScreen.hideAsync();
        }, 4000);
      }
    }
    hdd()
  },[fontsLoaded])

  const onLayoutRootView = useCallback(async () => {
    if (fontsLoaded) {
      await SplashScreen.hideAsync();
    }
  }, [fontsLoaded]);

  if (!fontsLoaded) {
    return null;
  }
  return (
    <Provider store={store}>
    <NavigationContainer
      linking={weblinking}
    >
      <HomeScreeNavigator />
    </NavigationContainer>
    </Provider>
  );
}