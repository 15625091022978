import * as React from "react"
import Svg, { Rect, Circle } from "react-native-svg"

function LineFull(props) {
  return (
    <Svg
      width={props.width}
      height={7}
      viewBox="0 0 357 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Rect x={34} y={2} width={319} height={3} rx={1.5} fill="#D9D9D9" />
      <Rect x={4} y={2} width={349} height={3} rx={1.5} fill="#011627" />
      <Circle cx={3.5} cy={3.5} r={3.5} fill="#011627" />
      <Circle cx={353.5} cy={3.5} r={3.5} fill="#D9D9D9" />
      <Circle cx={353.5} cy={3.5} r={3.5} fill="#011627" />
    </Svg>
  )
}

export default LineFull