import * as React from "react"
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg"

function SfProfiesional(props) {
  return (
    <Svg
      width={37}
      height={37}
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G clipPath="url(#clip0_50_1321)">
        <Path
          d="M34.047.542l-2.168 5.059V34.29a2.167 2.167 0 01-2.168 2.168H5.935l2.168-3.923V2.71A2.168 2.168 0 0110.271.542h23.776z"
          fill="#F0F0F2"
        />
        <Path
          d="M27.253 31.4H3.55v2.89a2.168 2.168 0 002.168 2.168h24.065"
          fill="#DADADC"
        />
        <Path
          d="M27.326 31.4v2.782a2.276 2.276 0 104.552 0v-2.783"
          fill="#F0F0F2"
        />
        <Path
          d="M34.168.542a2.29 2.29 0 012.29 2.29V5.6h-4.58V2.83a2.29 2.29 0 012.29-2.289zm-7.66 12.104H11.355a.542.542 0 110-1.084h15.153a.542.542 0 010 1.085zm-2.66 3.252H11.355a.542.542 0 010-1.083h12.493a.542.542 0 010 1.083zm-.992 3.252H14.38a.542.542 0 110-1.084h8.475a.542.542 0 110 1.084zm.992 3.252h-9.25a.542.542 0 010-1.084h9.25a.542.542 0 010 1.084zm2.66 3.252H13.5a.542.542 0 010-1.084h13.008a.542.542 0 110 1.084zm2.168 3.252h-10.84a.542.542 0 010-1.084h10.84a.542.542 0 010 1.084z"
          fill="#DADADC"
        />
        <Path
          d="M28.676 4.336h-3.414a.542.542 0 010-1.084h3.414a.542.542 0 010 1.084zm0 1.862h-3.414a.542.542 0 010-1.084h3.414a.542.542 0 110 1.084z"
          fill="#2C3AD9"
        />
        <Path
          d="M27.745 10.66a.541.541 0 01-.392-.167l-2.483-2.6a.542.542 0 01.392-.916h.62a1.32 1.32 0 000-2.641h-.62a.542.542 0 010-1.084h.62a2.405 2.405 0 01.58 4.738l1.675 1.754a.542.542 0 01-.392.917z"
          fill="#2C3AD9"
        />
        <Path
          d="M22.371 3.794H11.815c-.399 0-.722.323-.722.723V8.49c0 .4.323.723.722.723h10.556c.4 0 .723-.324.723-.723V4.517c0-.4-.324-.723-.723-.723z"
          fill="#DADADC"
        />
        <Path
          d="M31.878 11.676v13.648a6.83 6.83 0 110-13.648z"
          fill="#E1E1E3"
        />
        <Path
          d="M31.616 23.884A5.385 5.385 0 1137 18.5a5.39 5.39 0 01-5.384 5.384z"
          fill="#2C3AD9"
        />
        <Path
          d="M31.616 13.116c-.364 0-.727.037-1.084.11a5.385 5.385 0 010 10.548 5.385 5.385 0 101.084-10.658z"
          fill="#2C3AD9"
        />
        <Path
          d="M30.794 20.394a.542.542 0 01-.383-.159l-1.06-1.059a.542.542 0 11.768-.766l.675.675 2.32-2.32a.541.541 0 11.767.767l-2.704 2.703a.542.542 0 01-.383.159z"
          fill="#fff"
        />
        <Path
          d="M16.235 34.646a1.807 1.807 0 01-1.807 1.812H2.359a1.807 1.807 0 01-1.806-1.8l-.011-3.479a2.528 2.528 0 011.705-2.39l2.877-.994a2.52 2.52 0 001.042-.684c.417-.456 1.747-1.06 1.747-1.706l-1.084-1.81h3.108l-1.084 1.81c0 .62 1.309 1.2 1.694 1.648.292.34.67.597 1.094.742l2.878.993a2.527 2.527 0 011.705 2.391l.01 3.467z"
          fill="#2370B5"
        />
        <Path
          d="M16.224 31.179a2.528 2.528 0 00-1.705-2.39l-2.878-.994a2.515 2.515 0 01-1.094-.742c-.385-.448-1.694-1.029-1.694-1.648l1.084-1.81H8.853l-1.012 1.69.072.12a.58.58 0 01-.07.263c.282.52 1.29 1 1.62 1.385.292.34.67.597 1.094.742l2.878.993a2.527 2.527 0 011.705 2.391l.01 3.467a1.807 1.807 0 01-1.806 1.812h1.084a1.805 1.805 0 001.806-1.812l-.01-3.467z"
          fill="#1067AE"
        />
        <Path
          d="M9.129 27.824l.988 4.678a.722.722 0 01-.152.612l-1.027 1.232a.723.723 0 01-1.11 0L6.8 33.114a.723.723 0 01-.151-.612l.989-4.689 1.49.01z"
          fill="#FED947"
        />
        <Path
          d="M10.117 32.502l-.988-4.678-1.491-.01-.002.007.409.003.988 4.678a.722.722 0 01-.152.612l-1.027 1.232-.013.015a.723.723 0 001.097-.015l1.027-1.232a.722.722 0 00.152-.612z"
          fill="#FED030"
        />
        <Path
          d="M10.547 27.053l-.114.131a3.319 3.319 0 01-1.877.72 3.246 3.246 0 01-1.194-.16 3.337 3.337 0 01-.589-.268c-.188-.107-.364-.23-.552-.335a3.307 3.307 0 00-.055-.03 2.525 2.525 0 00.663-1.706v-1.81h3.108v1.81c0 .605.216 1.19.61 1.648z"
          fill="#FDD8AE"
        />
        <Path
          d="M9.937 25.405v-1.81H8.853v1.81c0 .605.216 1.19.61 1.648l-.114.131a3.343 3.343 0 01-1.507.679c.236.04.475.054.714.042a3.319 3.319 0 001.877-.72l.114-.132a2.525 2.525 0 01-.61-1.648z"
          fill="#FBBF7D"
        />
        <Path
          d="M10.623 19.042l1.084 1.132v2.265a3.324 3.324 0 11-6.648 0v-1.181l1.084-2.216c0-1.038-.243-.795.795-.795h2.89c1.038 0 .795-.243.795.795z"
          fill="#FDD8AE"
        />
        <Path
          d="M10.623 19.042v3.396a3.325 3.325 0 01-2.782 3.28 3.323 3.323 0 003.866-3.28v-2.264l-1.084-1.132zm-3.685-.795c-.496 0-.7-.055-.777 0h.777zm3.073-.003c.376.058.612.248.612.798 0-.972.213-.82-.612-.798z"
          fill="#FBBF7D"
        />
        <Path
          d="M11.707 19.042v1.132l-3.865-.95a1.011 1.011 0 00-.945.255l-1.838 1.779v-2.216a1.879 1.879 0 011.878-1.879h2.891a1.879 1.879 0 011.879 1.879z"
          fill="#787681"
        />
        <Path
          d="M9.828 17.163H8.744a1.879 1.879 0 011.879 1.879v.865l1.084.267v-1.132a1.879 1.879 0 00-1.879-1.879z"
          fill="#57565C"
        />
        <Path
          d="M13.694 33.821v2.637H12.61v-2.637a.542.542 0 011.084 0zm-9.611 0v2.637H2.999v-2.637a.542.542 0 011.084 0z"
          fill="#1067AE"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_50_1321">
          <Path fill="#fff" d="M0 0H37V37H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export default SfProfiesional
