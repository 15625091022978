import * as React from "react"
import Svg, { Circle, G, Path, Defs, ClipPath } from "react-native-svg"

function BikeLoanSvg(props) {
  return (
    <Svg
      width={props.width}
      height={props.height}
      viewBox="0 0 67 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Circle
        cx={33.15}
        cy={33.15}
        r={33.15}
        fill="#F2F2FF"
        fillOpacity={0.55}
      />
      <G clipPath="url(#clip0_120_1421)">
        <Path
          d="M45.391 42.393a4.076 4.076 0 00-.969-.115h-.038a4.04 4.04 0 00-.853.098v-3.18c0-7.378-6.003-13.381-13.382-13.381h-.859v-1.86h.859c8.405 0 15.242 6.837 15.242 15.242v3.196z"
          fill="#548A3E"
        />
        <Path
          d="M32.26 17.614v1.94a1.002 1.002 0 01-1.002 1.001H24.96a1.002 1.002 0 01-1.002-1.002v-.644a.93.93 0 01.93-.93h1.836a1.78 1.78 0 001.635-1.073 1.782 1.782 0 011.635-1.074h.483a1.782 1.782 0 011.782 1.782z"
          fill="#00ACEA"
        />
        <Path
          d="M29.29 25.815v4.065a10.5 10.5 0 00-2.362 0v-9.325h2.362v5.26zM36.442 39.662h-7.044l4.986-4.986-.81-.81-4.932 4.933v-6.937h-1.145v6.856l-4.852-4.852-.81.81 4.986 4.986H19.84v1.145h6.793l-4.798 4.799.81.81 4.852-4.853v6.901l1.145-.005v-6.977l4.933 4.933.81-.81-4.8-4.798h6.857v-1.145z"
          fill="#2C3AD9"
        />
        <Path
          d="M26.928 29.88A10.327 10.327 0 1129.29 50.4a10.327 10.327 0 01-2.36-20.518zM28.07 48.46h.04a8.318 8.318 0 008.318-8.224l.001-.094a8.32 8.32 0 00-8.319-8.319h-.04a8.317 8.317 0 00-5.822 14.22 8.315 8.315 0 005.822 2.417z"
          fill="#548A3E"
        />
        <Path
          d="M47.723 43.072a4.664 4.664 0 00-3.3-1.367h-.054a4.667 4.667 0 104.721 4.668 4.639 4.639 0 00-1.367-3.3zm.189 2.816h-2.195l1.568-1.568c.334.463.55 1.002.627 1.568zm-4.1 3.954a3.482 3.482 0 01-1.442-.607l1.443-1.443v2.05zm1.146-2.125l1.518 1.518c-.45.325-.97.537-1.518.62v-2.138zm1.516-4.206l-1.516 1.517v-2.136a3.5 3.5 0 011.517.62zm-2.818-.577a3.45 3.45 0 01.157-.03v2.05L42.37 43.51a3.493 3.493 0 011.286-.576zM41.56 44.32l1.568 1.568h-2.194a3.483 3.483 0 01.626-1.568zm-.597 2.713h1.99l-1.393 1.392a3.49 3.49 0 01-.597-1.392zm6.322 1.392l-1.392-1.392h1.99a3.491 3.491 0 01-.598 1.392zM43.435 27.883a5.386 5.386 0 113.808-1.574 5.35 5.35 0 01-3.808 1.574z"
          fill="#2C3AD9"
        />
        <Path
          d="M47.243 18.69a5.35 5.35 0 00-3.808-1.574c-.3 0-.599.025-.895.075a5.385 5.385 0 010 10.62 5.385 5.385 0 004.702-9.118v-.003z"
          fill="#2C3AD9"
        />
        <Path
          d="M43.435 28.42a5.923 5.923 0 114.187-1.735 5.885 5.885 0 01-4.187 1.735zm0-10.77a4.85 4.85 0 103.428 1.42 4.814 4.814 0 00-3.428-1.42z"
          fill="#2C3AD9"
        />
        <Path
          d="M42.845 23.528H41.49a.536.536 0 110-1.073h1.354a1.127 1.127 0 000-2.254h-.973a.537.537 0 110-1.073h.973a2.2 2.2 0 010 4.4z"
          fill="#fff"
        />
        <Path
          d="M45.42 20.201h-3.93a.536.536 0 110-1.073h3.93a.537.537 0 110 1.073zm0 1.637h-3.93a.536.536 0 110-1.074h3.93a.537.537 0 110 1.074zm-.976 4.579a.535.535 0 01-.374-.152l-2.977-2.892a.538.538 0 01.75-.77l2.977 2.892a.536.536 0 01-.374.922h-.002z"
          fill="#fff"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_120_1421">
          <Path
            fill="#fff"
            transform="translate(14.83 14.83)"
            d="M0 0H36.6395V36.6395H0z"
          />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export default BikeLoanSvg
