const appColor = {
    'primary':'#2C3AD9',
    'red': '#EA5050',
    'txheadblack':'#1E1E1E',
    'txgray':'#727275',
    'gray':'#F3F3F3',
    'background':'#FFFFFF',
    'txt':'#333333'
}

export default appColor