import React from 'react';
import { StyleSheet, View, TouchableOpacity, Dimensions, Text, Platform } from 'react-native';
import appColor from '../../constants/other/colors';
import appfont from '../../constants/other/font';
import BusinessSvg from '../../constants/svg/loan/Business';
import SfProfiesional from '../../constants/svg/loan/Profesional';
import SalarySvg from '../../constants/svg/loan/salary';
import CircleRightSvg from '../../constants/svg/loan/CircleRight';

const empdata = [
    {
        'id':1,
        'name':'Salary',
        'svg':<SalarySvg />
    },
    {
        'id':2,
        'name':'Self Employed Business',
        'svg':<BusinessSvg/>
    },
    {
        'id':3,
        'name':'Self Employed Professional',
        'svg':<SfProfiesional/>
    },
]

export default function Employmenttype({ emptype, onPress}) {

    const handleItemPress = (item) => {
        onPress(item);
      };

  return (
    <View style={styles.hmain}>
        <Text style={styles.hmtxt}>What is your employment type?</Text>
        <View style={styles.inmain}>
        {
            empdata.map((item) => {
                return(
                    <TouchableOpacity key={item.id} style={styles.tch} onPress={() => handleItemPress(item)}>
                        <View style={styles.lft}>
                            <View style={styles.svv}>
                                {item.svg}
                            </View>
                            <Text style={styles.lntptxt}>{item.name}</Text>
                        </View>
                        {
                            emptype == item.id ?
                            <CircleRightSvg />:
                            <View style={styles.circle}>
                            </View>
                        }
                    </TouchableOpacity>
                )
            })
        }
        </View>
    </View>
  );
}

const screenWidth = Dimensions.get('window').width

const styles = StyleSheet.create({
    hmain:{
        width:screenWidth-48,
        marginTop:25,
    },
    inmain:{
        width:screenWidth-48,
        alignItems:'center'
    },
    tch:{
        width:screenWidth-55,
        height:113,
        borderRadius:15,
        backgroundColor:appColor.background,
        marginBottom:26,
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        paddingLeft:30,
        paddingRight:30,
        ...Platform.select({
            android: { elevation: 5 },
            ios: { 
              shadowColor: '#000',
              shadowOffset: { width: 0, height: 2 },
              shadowOpacity: 0.2,
              shadowRadius: 3.84,
            },
            web:{
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
            },
        })
    },
    hmtxt:{
        fontFamily:appfont.bold,
        fontSize:18,
        color:appColor.txheadblack,
        marginBottom:20
    },
    svv:{
        height:42,
        width:42,
        justifyContent:'center',
        alignItems:'center',
        marginRight:18
    },
    lft:{
        flexDirection:'row',
        alignItems:'center',
    },
    circle:{
        height:25,
        width:25,
        borderRadius:25,
        borderWidth:1,
        borderColor:'#D9D9D9'
    },
    lntptxt:{
        fontFamily:appfont.semibold,
        fontSize:15,
        color:'#37474F',
        width:screenWidth/2.3
    }

});