import * as React from "react"
import Svg, { Circle, Path } from "react-native-svg"

function MicroLoanSvg(props) {
  return (
    <Svg
      width={props.width}
      height={props.height}
      viewBox="0 0 67 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Circle
        cx={33.4641}
        cy={33.1521}
        r={33.1521}
        fill="#F2F2FF"
        fillOpacity={0.55}
      />
      <Path
        d="M34.868 19.187v-.573a1.145 1.145 0 012.29 0v.573"
        fill="#2C3AD9"
      />
      <Path
        d="M38.876 32.355l1.717 2.29 4.81 6.412c2.264 3.02.11 7.329-3.665 7.329H29.143c-3.774 0-5.93-4.31-3.664-7.329l4.809-6.412 1.717-2.29 1.145-5.725.573-3.435-.572-2.29-.573-1.718v-.573a1.145 1.145 0 112.29 0v.573h2.29v-.573a1.145 1.145 0 112.29 0v.573l-.572 1.717-.573 2.29.573 9.161z"
        fill="#2C3AD9"
      />
      <Path
        d="M38.675 29.151a1.113 1.113 0 00-.653-.23c.225-.006.443-.08.626-.212l-.122-1.948a1.119 1.119 0 00-.504-.129c.17-.002.337-.043.489-.12l-.208-3.315.287-1.145h-2.622a2.6 2.6 0 01-2.49-1.853l-.3-1.002v-.572c0-.535.374-.965.87-1.09-.105-.033-.21-.066-.325-.066a1.145 1.145 0 00-1.145 1.145v.573l.572 1.717.287 1.145.286 1.146-.572 3.435-1.146 5.725-1.717 2.29-4.81 6.412c-2.264 3.02-.11 7.329 3.665 7.329h.458c-3.685-.108-5.759-4.338-3.523-7.318l4.809-6.413 1.717-2.29.232-1.155h5.184c.297 0 .561-.12.764-.305l-.109-1.754z"
        fill="#2C3AD9"
      />
      <Path
        d="M38.876 32.355l-.502-8.015h-4.651l-.114-.458-.459 2.748-.113.568-1.032 5.157-1.717 2.29-4.81 6.413c-2.264 3.02-.11 7.328 3.665 7.328h2.29c-3.774 0-5.714-4.159-3.664-7.328.68-1.053 1.896-3.253 2.934-5.178a4.532 4.532 0 013.992-2.38h4.135c.26-.004.509-.098.706-.265l-.66-.88z"
        fill="#2C3AD9"
      />
      <Path
        d="M33.273 35.218a.571.571 0 01-.545-.745l.696-2.206v-.485a.573.573 0 01.571-.572l2.89-.005a.575.575 0 01.531.353.57.57 0 01.043.22v.489l.696 2.206a.574.574 0 01-.81.68.573.573 0 01-.282-.335l-.723-2.29a.593.593 0 01-.026-.172v-.003l-1.745.003a.578.578 0 01-.027.172l-.723 2.29a.576.576 0 01-.546.4z"
        fill="#2C3AD9"
      />
      <Path
        d="M35.44 35.217a.573.573 0 01-.572-.572v-2.29a.573.573 0 011.145 0v2.29a.572.572 0 01-.572.572z"
        fill="#2C3AD9"
      />
      <Path
        d="M39.974 26.63a1.145 1.145 0 00-1.145-1.145 1.145 1.145 0 100-2.29h-5.106l-.572-2.29h-1.536a1.142 1.142 0 00-1.024.633l-.512 1.023a1.145 1.145 0 01-1.023.633H26.28v8.016h3.008c.304 0 .596.12.81.335l.474.474c.215.215.506.336.81.336h7.447a1.145 1.145 0 000-2.29 1.145 1.145 0 100-2.29 1.145 1.145 0 001.145-1.145z"
        fill="#F0CAAF"
      />
      <Path
        d="M36.313 17.53c-.097-.028-.193-.06-.3-.06a1.145 1.145 0 00-1.145 1.144v.573h.6v-.573c0-.525.362-.95.845-1.084zm2.29 0c-.097-.028-.193-.06-.3-.06a1.145 1.145 0 00-1.145 1.144v.573h.6v-.573c0-.525.362-.95.845-1.084zm-4.58 0c-.097-.028-.193-.06-.3-.06a1.145 1.145 0 00-1.145 1.144v.573h.6v-.573c0-.525.362-.95.845-1.084z"
        fill="#2C3AD9"
      />
      <Path
        d="M37.73 39.785h-.628a2.845 2.845 0 00-.531-1.164h1.16a.572.572 0 000-1.145h-4.58a.572.572 0 000 1.145h1.145c.752 0 1.385.49 1.618 1.164H33.15a.573.573 0 000 1.145h2.752a1.714 1.714 0 01-1.606 1.127H33.15a.573.573 0 00-.573.572l.002.01a.566.566 0 00.166.413l3.435 3.435a.57.57 0 00.811 0 .573.573 0 000-.81l-2.496-2.496a2.858 2.858 0 002.6-2.252h.636a.575.575 0 00.598-.573.574.574 0 00-.6-.571z"
        fill="#EFF2FA"
      />
      <Path
        d="M36.141 30.065h2.688a1.145 1.145 0 110 2.29H36.14m0-4.58h2.688a1.145 1.145 0 110 2.29H36.14m0-4.58h2.688a1.145 1.145 0 110 2.29H36.14"
        fill="#F0CAAF"
      />
      <Path
        d="M33.723 23.194l-.572-2.29h-1.146l.356 1.424a1.146 1.146 0 001.111.866h.251zm6.186 3.114a1.13 1.13 0 01-1.094.89H36.9v.577h1.93a1.145 1.145 0 001.145-1.145c0-.114-.033-.218-.065-.322zm0 2.29a1.13 1.13 0 01-1.094.89H36.9v.577h1.93a1.145 1.145 0 001.145-1.145c0-.114-.033-.218-.065-.322zm0 2.29a1.13 1.13 0 01-1.094.89H36.14v.577h2.688a1.145 1.145 0 001.145-1.145c0-.114-.032-.218-.064-.322zm0-6.87a1.126 1.126 0 01-1.093.89l-2.083-.003v.577l2.097.003a1.145 1.145 0 001.145-1.145c-.001-.114-.034-.218-.066-.322z"
        fill="#E2B698"
      />
      <Path
        d="M31.292 31.782a1.14 1.14 0 01-.81-.335l-.473-.474a1.145 1.145 0 00-.81-.335H26.28v.572h3.008c.304 0 .596.12.81.335l.474.474c.215.215.506.336.81.336h6.396l-.136-.572h-6.35z"
        fill="#E2B698"
      />
      <Path
        d="M19.457 22.622h7.396a.572.572 0 01.572.573v9.16a.573.573 0 01-.572.573h-7.396"
        fill="#90CFF1"
      />
      <Path
        d="M25.182 29.54v-6.918h-5.725v10.306h7.396a.572.572 0 00.572-.573v-.572a2.243 2.243 0 01-2.243-2.244z"
        fill="#72B7E5"
      />
      <Path
        d="M17.167 22.05h6.25a.572.572 0 01.573.572v10.305a.572.572 0 01-.572.573h-6.251"
        fill="#4C6D86"
      />
      <Path
        d="M23.418 22.05H21.7v6.87a2.29 2.29 0 01-2.29 2.29h-2.243v2.29h6.25a.572.572 0 00.573-.573V22.622a.572.572 0 00-.572-.573z"
        fill="#41667D"
      />
      <Path
        d="M25.708 31.782a.572.572 0 100-1.144.572.572 0 000 1.144z"
        fill="#EFF2FA"
      />
      <Path
        d="M37.038 25.48h-.61a.286.286 0 110-.572h.61a.286.286 0 010 .572zm0 2.29h-.61a.286.286 0 010-.572h.61a.286.286 0 010 .572zm0 2.29h-.61a.286.286 0 010-.572h.61a.286.286 0 010 .573z"
        fill="#E2B698"
      />
    </Svg>
  )
}

export default MicroLoanSvg
