import * as React from "react"
import Svg, { Path } from "react-native-svg"

function RightSvg(props) {
  return (
    <Svg
      width={98}
      height={199}
      viewBox="0 0 98 199"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M24.897 116.131c-11.854-7.919-11.854-25.343 0-33.262l72.994-48.758C111.18 25.233 129 34.76 129 50.74v97.517c0 15.982-17.819 25.509-31.11 16.631l-72.993-48.758z"
        fill="#2C3AD9"
      />
      <Path
        d="M64.657 97.758l-.001-.001-5.298-5.452a.99.99 0 00-1.434.004 1.074 1.074 0 00.004 1.482l3.556 3.66h-21.47c-.56 0-1.014.47-1.014 1.049s.454 1.048 1.014 1.048h21.47l-3.556 3.66a1.074 1.074 0 00-.003 1.483.991.991 0 001.433.003l5.298-5.451v-.001a1.075 1.075 0 000-1.484z"
        fill="#fff"
      />
    </Svg>
  )
}

export default RightSvg