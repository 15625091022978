import React from 'react';
import { Modal, View, Text, Button } from 'react-native';
import LoadingAnimation from './Loading';

export default function LoadingModal({ visible, onClose }) {
  return (
    <Modal visible={visible} animationType="fade">
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center',}}>
        <LoadingAnimation />
      </View>
    </Modal>
  );
}