import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import appColor from '../../constants/other/colors'
import SadFaceSvg from '../../constants/svg/SadFace'
import appfont from '../../constants/other/font'

const OfferScreen = () => {
    return(
        <View style={styles.main}>
            <SadFaceSvg />
            <Text style={styles.txt}>No loan offer found for you</Text>
            <Text style={styles.trtxt}>Try again</Text>
        </View>
    )
}

export default OfferScreen

const styles = StyleSheet.create({
    main:{
        flex:1,
        backgroundColor:appColor.background,
        alignItems:'center',
        justifyContent:'center'
    },
    txt:{
        fontFamily:appfont.semibold,
        fontSize:15,
        color:appColor.txgray,
        marginTop:16
    },
    trtxt:{
        fontFamily:appfont.semibold,
        fontSize:15,
        color:appColor.primary,
        marginTop:4,
        opacity:0.7
    }
})