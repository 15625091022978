import * as React from "react"
import Svg, { Path, G } from "react-native-svg"

function Landing1Svg(props) {
  return (
    <Svg
      width={props.width}
      height={props.height}
      viewBox="0 0 309 309"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M254.814 153.184c-55.762-32.198-145.848-32.198-201.283 0-55.434 32.197-55.113 84.394.618 116.592 55.731 32.197 145.891 32.197 201.295 0 55.404-32.198 55.138-84.376-.63-116.592z"
        fill="#F5F5F5"
      />
      <Path
        d="M29.62 221.071c4.123-2.386 10.791-2.386 14.888 0 4.098 2.385 4.073 6.242-.05 8.621-4.121 2.379-10.79 2.386-14.887 0-4.097-2.385-4.079-6.242.05-8.621zM271.376 204.064c4.326-2.472 11.316-2.472 15.617 0s4.27 6.544-.049 9.041c-4.32 2.497-11.322 2.472-15.617 0-4.295-2.472-4.277-6.545.049-9.041zM203.143 161.076L44.713 253.59 38 249.715l158.43-92.514 6.712 3.875zM245.266 210.12c-15.45-8.918-40.399-8.918-55.738 0-13.559 7.873-15.06 20.073-4.579 28.959-8.461-.525-17.224 1.088-23.682 4.839-11.34 6.588-11.278 17.274.13 23.861 11.408 6.588 29.855 6.588 41.19 0 9.022-5.24 10.821-13.076 5.432-19.374 12.978 1.854 27.303-.117 37.426-6.001 15.351-8.899 15.264-23.354-.179-32.284z"
        fill="#E0E0E0"
      />
      <Path
        d="M42.364 137.289l-.025-8.819-4.598-2.614.025 8.608v.198l4.598 2.627zM42.642 239.401l-.29-102.112-4.598-2.627.284 101.921a1.937 1.937 0 00.772 1.724l4.536 2.769a1.91 1.91 0 01-.704-1.675z"
        fill="#2C3AD9"
      />
      <Path
        opacity={0.2}
        d="M42.642 239.401l-.29-102.112-4.598-2.627.284 101.921a1.937 1.937 0 00.772 1.724l4.536 2.769a1.91 1.91 0 01-.704-1.675z"
        fill="#fff"
      />
      <Path
        d="M42.358 128.451v-5.073a6.004 6.004 0 012.694-4.722l153.882-88.838a1.95 1.95 0 011.904-.203l-4.543-2.788a1.889 1.889 0 00-1.965.161L40.448 115.826a5.984 5.984 0 00-2.713 4.709v5.277l4.598 2.621.025.018z"
        fill="#2C3AD9"
      />
      <Path
        opacity={0.3}
        d="M42.358 128.451v-5.073a6.004 6.004 0 012.694-4.722l153.882-88.838a1.95 1.95 0 011.904-.203l-4.543-2.788a1.889 1.889 0 00-1.965.161L40.448 115.826a5.984 5.984 0 00-2.713 4.709v5.277l4.598 2.621.025.018z"
        fill="#fff"
      />
      <Path
        opacity={0.2}
        d="M42.364 137.289l-.025-8.819-4.598-2.614.025 8.608v.198l4.598 2.627z"
        fill="#000"
      />
      <Path
        opacity={0.2}
        d="M42.364 137.289l-.025-8.819-4.598-2.614.025 8.608v.198l4.598 2.627z"
        fill="#fff"
      />
      <Path
        d="M198.928 29.83c1.502-.864 2.725-.166 2.732 1.564v5.08L42.358 128.451v-5.073a6.004 6.004 0 012.694-4.722l153.876-88.825z"
        fill="#2C3AD9"
      />
      <Path
        d="M188.614 38.236c.803-.47 1.458-.093 1.458.828a3.2 3.2 0 01-1.452 2.515c-.804.47-1.459.093-1.459-.828a3.176 3.176 0 011.453-2.515zM193.057 35.665c.803-.464 1.459-.087 1.459.834a3.177 3.177 0 01-1.453 2.515c-.803.464-1.458.093-1.458-.834a3.21 3.21 0 011.452-2.515zM197.494 33.106c.81-.47 1.459-.092 1.465.828a3.21 3.21 0 01-1.452 2.528c-.804.47-1.459.093-1.465-.828a3.21 3.21 0 011.452-2.528z"
        fill="#fff"
      />
      <Path
        d="M74.395 104.918c.618-.346 1.156-.284 1.44.161l1.446 2.224c.29.445.84.507 1.44.161l122.932-71.002v8.831l-159.29 92.008v-8.831l4.722-2.726a3.638 3.638 0 001.44-1.823l1.422-3.881a3.582 3.582 0 011.433-1.817l23.015-13.305z"
        fill="#E0E0E0"
      />
      <Path
        d="M201.678 45.306l.291 102.118a5.987 5.987 0 01-2.713 4.697l-153.87 88.837c-1.508.872-2.726.173-2.732-1.557l-.29-102.112 159.314-91.983z"
        fill="#2C3AD9"
      />
      <Path
        d="M201.678 45.306l.291 102.118a5.987 5.987 0 01-2.713 4.697l-153.87 88.837c-1.508.872-2.726.173-2.732-1.557l-.29-102.112 159.314-91.983z"
        fill="#fff"
        opacity={0.4}
      />
      <Path
        d="M195.381 41.913c1.069-.618 1.934-.124 1.94 1.106a4.266 4.266 0 01-1.928 3.331L60.564 124.218c-1.07.618-1.94.117-1.947-1.112a4.262 4.262 0 011.935-3.338L195.38 41.913z"
        fill="#fff"
      />
      <Path
        d="M44.91 129.255a.186.186 0 01.204 0c.099.068.093.272 0 .451l-.847 1.39 2.213-1.316a.166.166 0 01.272.154.584.584 0 01-.272.476l-2.231 1.286.853.408c.11.055.123.247 0 .426a.622.622 0 01-.198.223.226.226 0 01-.096.022.226.226 0 01-.096-.022l-1.366-.656-.055-.055v-.247l.05-.112 1.365-2.237a.497.497 0 01.204-.191zM49.743 126.468a.2.2 0 01.185 0l1.366.624.05.062v.278l-.044.111-1.36 2.237a.53.53 0 01-.185.185.175.175 0 01-.099.03.175.175 0 01-.099-.03c-.105-.061-.099-.265 0-.451l.847-1.384-2.2 1.31c-.148.08-.272 0-.272-.161a.615.615 0 01.266-.469l2.237-1.286-.853-.408c-.117-.055-.117-.247 0-.438a.533.533 0 01.16-.21zM54.328 123.816c.274-.178.608-.24.927-.173l.204-.272a.522.522 0 01.149-.136.267.267 0 01.148 0c.093 0 .136.112.124.248l-.118.84a.614.614 0 01-.117.278.504.504 0 01-.149.136l-.068.031-.66.185a.157.157 0 01-.139-.019.154.154 0 01-.066-.123.526.526 0 01.124-.377l.062-.086a.864.864 0 00-.414.123 2.176 2.176 0 00-.983 1.694c0 .618.445.877.989.562.398-.252.7-.631.859-1.075a.617.617 0 01.235-.303.27.27 0 01.13-.037c.135 0 .185.173.11.364a3.35 3.35 0 01-1.334 1.632c-.84.482-1.527.093-1.533-.878a3.36 3.36 0 011.52-2.614z"
        fill="#E0E0E0"
      />
      <Path
        d="M44.91 129.255a.186.186 0 01.204 0c.099.068.093.272 0 .451l-.847 1.39 2.213-1.316a.166.166 0 01.272.154.584.584 0 01-.272.476l-2.231 1.286.853.408c.11.055.123.247 0 .426a.622.622 0 01-.198.223.226.226 0 01-.096.022.226.226 0 01-.096-.022l-1.366-.656-.055-.055v-.247l.05-.112 1.365-2.237a.497.497 0 01.204-.191zM49.743 126.468a.2.2 0 01.185 0l1.366.624.05.062v.278l-.044.111-1.36 2.237a.53.53 0 01-.185.185.175.175 0 01-.099.03.175.175 0 01-.099-.03c-.105-.061-.099-.265 0-.451l.847-1.384-2.2 1.31c-.148.08-.272 0-.272-.161a.615.615 0 01.266-.469l2.237-1.286-.853-.408c-.117-.055-.117-.247 0-.438a.533.533 0 01.16-.21zM54.328 123.816c.274-.178.608-.24.927-.173l.204-.272a.522.522 0 01.149-.136.267.267 0 01.148 0c.093 0 .136.112.124.248l-.118.84a.614.614 0 01-.117.278.504.504 0 01-.149.136l-.068.031-.66.185a.157.157 0 01-.139-.019.154.154 0 01-.066-.123.526.526 0 01.124-.377l.062-.086a.864.864 0 00-.414.123 2.176 2.176 0 00-.983 1.694c0 .618.445.877.989.562.398-.252.7-.631.859-1.075a.617.617 0 01.235-.303.27.27 0 01.13-.037c.135 0 .185.173.11.364a3.35 3.35 0 01-1.334 1.632c-.84.482-1.527.093-1.533-.878a3.36 3.36 0 011.52-2.614z"
        fill="#000"
        opacity={0.3}
      />
      <Path
        d="M194.608 75.161l.142 36.716a9.484 9.484 0 01-4.715 8.213l-79.543 46.152a3.146 3.146 0 01-3.152.007 3.159 3.159 0 01-1.582-2.726l-.136-36.716a9.465 9.465 0 014.71-8.213l79.542-46.14a3.154 3.154 0 014.304 1.136c.278.477.427 1.019.43 1.571zM147.702 151.787l.062 22.174a4.296 4.296 0 01-2.132 3.708l-37.81 21.945a1.426 1.426 0 01-2.138-1.236l-.062-22.18a4.265 4.265 0 012.132-3.708l37.797-21.933a1.428 1.428 0 012.151 1.23zM194.176 124.836l.061 22.174a4.273 4.273 0 01-2.132 3.708l-37.605 21.55a1.436 1.436 0 01-1.43.002 1.433 1.433 0 01-.714-1.238l-.062-22.174a4.282 4.282 0 012.132-3.708l37.605-21.55a1.425 1.425 0 011.953.521c.126.217.192.464.192.715z"
        fill="#fff"
        opacity={0.6}
      />
      <G opacity={0.7} fill="#fff">
        <Path d="M84.314 123.198l.012 5.593-31.994 18.472-.012-5.586 31.994-18.479zM71.472 139.266l.012 2.781-19.133 11.05-.006-2.781 19.127-11.05zM123.26 100.74l.012 3.263-9.263 5.346-.013-3.257 9.264-5.352zM141.028 90.481l.006 3.263-12.348 7.132-.006-3.263 12.348-7.132zM176.742 69.865l.006 3.263-9.264 5.346-.006-3.257 9.264-5.352zM194.509 59.606l.007 3.263-12.348 7.132-.012-3.263 12.353-7.132zM161.119 78.882l.006 3.263-14.739 8.516-.013-3.263 14.746-8.516z" />
      </G>
      <G opacity={0.7} fill="#fff">
        <Path d="M84.56 154.617l.007 2.466-32.18 18.577-.005-2.466 32.179-18.577zM65.026 170.179l.012 2.459-12.638 7.299-.006-2.466 12.632-7.292zM84.598 169.066l.006 2.466-32.173 18.577-.006-2.466 32.173-18.577zM84.586 164.79l.006 2.459-32.173 18.577-.006-2.459 32.173-18.577zM84.549 150.341l.006 2.459-32.18 18.578-.006-2.46 32.18-18.577zM77.664 177.354l.006 2.459-25.227 14.567-.012-2.46 25.233-14.566z" />
      </G>
      <G opacity={0.7} fill="#fff">
        <Path d="M149.192 113.277l.006 2.465-32.179 18.577-.007-2.465 32.18-18.577zM129.657 128.838l.012 2.459-12.638 7.299-.006-2.466 12.632-7.292zM149.229 127.725l.006 2.466-32.173 18.577-.006-2.465 32.173-18.578zM149.216 123.449l.007 2.46-32.174 18.577-.006-2.46 32.173-18.577zM149.179 109l.006 2.46-32.179 18.577-.006-2.46L149.179 109zM142.295 136.013l.006 2.459-25.227 14.567-.012-2.46 25.233-14.566z" />
      </G>
      <G opacity={0.7} fill="#fff">
        <Path d="M191.192 88.277l.006 2.465-32.179 18.577-.007-2.465 32.18-18.577zM171.657 103.838l.012 2.459-12.638 7.299-.006-2.466 12.632-7.292zM191.229 102.725l.006 2.466-32.173 18.577-.006-2.465 32.173-18.578zM191.216 98.449l.007 2.46-32.174 18.577-.006-2.46 32.173-18.577zM191.179 84l.006 2.46-32.179 18.577-.006-2.46L191.179 84zM184.295 111.013l.006 2.459-25.227 14.567-.012-2.46 25.233-14.566z" />
      </G>
      <Path
        d="M83.615 192.816c2.769-1.601 5.025-.315 5.037 2.868.013 3.182-2.23 7.063-5.006 8.652l-26.932 15.555c-2.775 1.6-5.03.321-5.037-2.868-.006-3.189 2.231-7.064 5-8.652l26.938-15.555z"
        fill="#2C3AD9"
        opacity={0.7}
      />
      <Path
        d="M66.256 206.424l.025 4.314-1.156.661-.019-1.959-.81 2.052-.556.321-.821-1.038.006 1.897-1.156.668-.012-4.32 1.038-.599 1.236 1.594 1.186-2.991 1.039-.6zM68.851 204.83c1.236-.705 2.12-.272 2.126 1.032a4.326 4.326 0 01-2.113 3.486c-1.236.704-2.126.271-2.132-1.033a4.327 4.327 0 012.12-3.485zm0 3.337a1.85 1.85 0 00.84-1.563c0-.674-.376-.866-.846-.618a1.851 1.851 0 00-.834 1.563c0 .674.377.859.84.618zM74.253 204.799l.797.871-1.353.785-.618-.717-.347.198v1.094l-1.236.729v-4.326l1.811-1.05c1.045-.618 1.712-.359 1.718.618a2.738 2.738 0 01-.772 1.798zm-1.088-.229a.904.904 0 00.513-.797c0-.315-.167-.414-.513-.21l-.464.266v1.007l.458-.266M78.486 199.361l.006 1.106-1.81 1.038v.507l1.588-.915.006 1.045-1.588.914v.575l1.878-1.088v1.1l-3.12 1.799-.013-4.314 3.053-1.767z"
        fill="#fff"
      />
      <Path
        d="M156.515 263.837l-.007 2.7-18.379-10.611.006-2.701 18.38 10.612zM156.527 259.758l-.012 2.707-7.472-4.314.006-2.713 7.478 4.32zM126.053 250.327l-.006 2.707-13.231-7.639.006-2.706 13.231 7.638zM134.044 250.865l-.006 2.7-21.21-12.242.006-2.707 21.21 12.249zM145.49 253.386l-.007 2.707-20.993-12.119-2.046-1.18-9.746-5.624.007-2.713 9.752 5.63 2.045 1.18 20.988 12.119zM139.371 240.217l-.006 2.7-26.5-15.295.013-2.701 26.493 15.296zM137.851 235.254l-.012 2.701-5.173-2.991.006-2.701 5.179 2.991zM136.343 256.272l-.006 2.701-7.472-4.314.007-2.7 7.471 4.313zM156.564 246.057l-.006 2.7-14.733-8.503.006-2.707 14.733 8.51zM156.576 241.984l-.006 2.701-10.302-5.945-2.274-1.311-13.652-7.885.007-2.701 13.651 7.88 2.274 1.316 10.302 5.945zM145.564 227.461l-.013 2.701-17.143-9.901.006-2.7 17.15 9.9zM128.377 229.785l-.006 2.7-15.493-8.942.006-2.701 15.493 8.943zM126.128 224.408l-.007 2.701-13.231-7.639.006-2.7 13.232 7.638zM124.57 215.342l-.006 2.701-11.791-6.804.006-2.707 11.791 6.81zM134.118 224.94l-.006 2.7-21.21-12.242.007-2.701 21.209 12.243zM130.219 214.52l-.006 2.707-17.428-10.061.006-2.707 17.428 10.061zM156.589 237.911l-.006 2.707-18.38-10.611.006-2.707 18.38 10.611zM156.601 233.833l-.012 2.7-7.472-4.313.006-2.701 7.478 4.314z"
        fill="#2C3AD9"
      />
      <G opacity={0.6} fill="#fff">
        <Path d="M156.515 263.837l-.006 2.7-18.38-10.611.006-2.701 18.38 10.612zM156.527 259.758l-.012 2.707-7.472-4.314.006-2.713 7.478 4.32zM126.053 250.327l-.006 2.707-13.231-7.639.006-2.706 13.231 7.638zM134.044 250.865l-.006 2.7-21.21-12.242.006-2.707 21.21 12.249zM145.49 253.386l-.007 2.707-20.993-12.119-2.046-1.18-9.746-5.624.007-2.713 9.752 5.63 2.045 1.18 20.988 12.119zM139.371 240.217l-.006 2.7-26.5-15.295.013-2.701 26.493 15.296zM137.851 235.254l-.012 2.701-5.173-2.991.006-2.701 5.179 2.991zM136.343 256.272l-.006 2.701-7.472-4.314.007-2.7 7.471 4.313zM156.564 246.057l-.006 2.7-14.733-8.503.006-2.707 14.733 8.51zM156.577 241.984l-.007 2.701-10.302-5.945-2.274-1.311-13.652-7.885.007-2.701 13.651 7.88 2.274 1.316 10.303 5.945zM145.564 227.461l-.013 2.701-17.143-9.901.006-2.7 17.15 9.9zM128.377 229.785l-.006 2.7-15.493-8.942.006-2.701 15.493 8.943zM126.128 224.408l-.007 2.701-13.231-7.639.006-2.7 13.232 7.638zM124.57 215.342l-.006 2.701-11.791-6.804.006-2.707 11.791 6.81zM134.118 224.94l-.006 2.7-21.21-12.242.007-2.701 21.209 12.243zM130.219 214.52l-.006 2.707-17.428-10.061.006-2.707 17.428 10.061zM156.589 237.911l-.006 2.707-18.38-10.611.007-2.707 18.379 10.611zM156.601 233.833l-.012 2.7-7.472-4.313.006-2.701 7.478 4.314z" />
      </G>
      <Path
        d="M103.824 211.208l-6.266-3.708a.315.315 0 01-.155-.272V168.17l-8.084-4.678.316-.544 8.238 4.771a.318.318 0 01.16.272v39.076l6.112 3.597-.321.544z"
        fill="#2C3AD9"
      />
      <Path
        d="M105.629 209.317a4.946 4.946 0 012.231 3.868c0 1.422-1.008 1.996-2.25 1.28a4.94 4.94 0 01-2.231-3.869c.006-1.422 1.014-1.996 2.25-1.279zM90.642 161.298a4.946 4.946 0 012.237 3.869c0 1.421-1.013 1.996-2.25 1.279a4.946 4.946 0 01-2.237-3.869c-.018-1.415 1.014-1.99 2.25-1.279z"
        fill="#2C3AD9"
      />
      <G opacity={0.2} fill="#fff">
        <Path d="M103.824 211.208l-6.266-3.708a.315.315 0 01-.155-.272V168.17l-8.084-4.678.316-.544 8.238 4.771a.318.318 0 01.16.272v39.076l6.112 3.597-.321.544z" />
        <Path d="M105.629 209.317a4.946 4.946 0 012.231 3.868c0 1.422-1.008 1.996-2.25 1.28a4.94 4.94 0 01-2.231-3.869c.006-1.422 1.014-1.996 2.25-1.279zM90.642 161.298a4.946 4.946 0 012.237 3.869c0 1.421-1.013 1.996-2.25 1.279a4.946 4.946 0 01-2.237-3.869c-.018-1.415 1.014-1.99 2.25-1.279z" />
      </G>
      <Path
        d="M265.814 81.329l-.012 2.707-18.373-10.611.006-2.707 18.379 10.61zM265.82 77.25l-.006 2.713-7.471-4.32.006-2.707 7.471 4.314zM235.353 67.826l-.006 2.7-13.232-7.638.007-2.701 13.231 7.639zM243.344 68.357l-.007 2.707-21.209-12.249.006-2.7 21.21 12.242zM254.783 70.878l-.006 2.713-20.988-12.119-2.045-1.18-9.746-5.63.006-2.713 9.746 5.63 2.046 1.18 20.987 12.12zM245.643 73.764l-.013 2.701-7.465-4.307.006-2.707 7.472 4.314z"
        fill="#2C3AD9"
      />
      <G opacity={0.6} fill="#fff">
        <Path d="M265.814 81.329l-.012 2.707-18.373-10.611.006-2.707 18.379 10.61zM265.82 77.25l-.006 2.713-7.471-4.32.006-2.707 7.471 4.314zM235.353 67.826l-.006 2.7-13.232-7.638.007-2.701 13.231 7.639zM243.344 68.357l-.007 2.707-21.209-12.249.006-2.7 21.21 12.242zM254.783 70.878l-.006 2.713-20.988-12.119-2.045-1.18-9.746-5.63.006-2.713 9.746 5.63 2.046 1.18 20.987 12.12zM245.643 73.764l-.013 2.701-7.465-4.307.006-2.707 7.472 4.314z" />
      </G>
      <Path
        d="M265.82 91.866l-.006 2.707-7.471-4.314.006-2.707 7.471 4.314zM243.344 82.973l-.007 2.7-21.209-12.242.006-2.7 21.21 12.242zM254.783 85.494l-.006 2.707-20.988-12.113-2.045-1.186-9.746-5.624.006-2.713 9.746 5.63 2.046 1.18 20.987 12.12z"
        fill="#2C3AD9"
      />
      <G opacity={0.6} fill="#fff">
        <Path d="M265.82 91.866l-.006 2.707-7.471-4.314.006-2.707 7.471 4.314zM243.344 82.973l-.007 2.7-21.209-12.242.006-2.7 21.21 12.242zM254.783 85.494l-.006 2.707-20.988-12.113-2.045-1.186-9.746-5.624.006-2.713 9.746 5.63 2.046 1.18 20.987 12.12z" />
      </G>
      <Path
        d="M233.159 83.43c-1.236-.717-2.243-.142-2.243 1.28.019.689.201 1.365.531 1.97l-31.518 18.244-9.937-5.797a4.95 4.95 0 00-2.231-3.831c-1.236-.717-2.244-.143-2.25 1.279a4.956 4.956 0 002.231 3.87c1.032.617 1.891.289 2.157-.62l10.03 5.853 31.821-18.484a4.33 4.33 0 001.378 1.36c1.236.716 2.243.141 2.25-1.28a4.951 4.951 0 00-2.219-3.844z"
        fill="#2C3AD9"
      />
      <Path
        opacity={0.2}
        d="M233.159 83.43c-1.236-.717-2.243-.142-2.243 1.28.019.689.201 1.365.531 1.97l-31.518 18.244-9.937-5.797a4.95 4.95 0 00-2.231-3.831c-1.236-.717-2.244-.143-2.25 1.279a4.956 4.956 0 002.231 3.87c1.032.617 1.891.289 2.157-.62l10.03 5.853 31.821-18.484a4.33 4.33 0 001.378 1.36c1.236.716 2.243.141 2.25-1.28a4.951 4.951 0 00-2.219-3.844z"
        fill="#fff"
      />
      <Path
        d="M196.987 179.418c-5.265 3.658-11.198 6.18-15.592 11.093-8.219 9.152-8.411 23.892-1.959 34.361 6.452 10.469 18.454 16.766 30.684 18.212 12.817 1.508 26.265-2.033 36.326-10.123 10.061-8.089 16.451-20.734 16.445-33.637-.006-12.904-6.613-25.759-17.421-32.81-9.888-6.433-24.041-8.269-33.935-.909-5.006 3.708-8.337 9.215-13.33 12.948-.408.296-.81.58-1.218.865z"
        fill="#37474F"
      />
      <Path
        opacity={0.2}
        d="M196.987 179.418c-5.265 3.658-11.198 6.18-15.592 11.093-8.219 9.152-8.411 23.892-1.959 34.361 6.452 10.469 18.454 16.766 30.684 18.212 12.817 1.508 26.265-2.033 36.326-10.123 10.061-8.089 16.451-20.734 16.445-33.637-.006-12.904-6.613-25.759-17.421-32.81-9.888-6.433-24.041-8.269-33.935-.909-5.006 3.708-8.337 9.215-13.33 12.948-.408.296-.81.58-1.218.865z"
        fill="#000"
      />
      <Path
        opacity={0.1}
        d="M196.987 179.418c-5.265 3.658-11.198 6.18-15.592 11.093-8.219 9.152-8.411 23.892-1.959 34.361 6.452 10.469 18.454 16.766 30.684 18.212 12.817 1.508 26.265-2.033 36.326-10.123 10.061-8.089 16.451-20.734 16.445-33.637-.006-12.904-6.613-25.759-17.421-32.81-9.888-6.433-24.041-8.269-33.935-.909-5.006 3.708-8.337 9.215-13.33 12.948-.408.296-.81.58-1.218.865z"
        fill="#fff"
      />
      <Path
        opacity={0.1}
        d="M199.132 233.073l.037-.26a13.864 13.864 0 0111.235-11.569 43.673 43.673 0 0014.387-5.513c3.146-1.897 6.112-4.227 9.641-5.259a15.702 15.702 0 0110.389.618c-2.961-3.09-8.034-4.486-12.107-3.09 2.812-4.326 5.284-8.936 6.254-13.985.97-5.049.284-10.586-2.762-14.727a14.828 14.828 0 00-11.142-6.014 14.833 14.833 0 00-11.724 4.778c-3.016 3.3-4.716 7.935-6.693 11.94-1.428 2.88-2.892 5.821-5.241 8.034-3.535 3.306-8.621 4.499-12.286 7.663-4.159 3.591-5.951 9.326-5.97 14.832.07 3.706.609 7.388 1.607 10.957a41.62 41.62 0 0015.036 9.19 16.337 16.337 0 01-.661-7.595z"
        fill="#000"
      />
      <Path
        d="M256 197.55a37.695 37.695 0 00-32.136-36.573 27.637 27.637 0 00-3.634.618 36.717 36.717 0 0134.775 35.98c.235 12.014-5.636 24.256-15.715 32.754-7.898 6.662-18.392 11.074-29.924 12.662l.742.099c1.032.118 2.076.204 3.09.26 10.24-1.984 19.522-6.211 26.697-12.261 10.339-8.696 16.346-21.229 16.105-33.539z"
        fill="#fff"
        opacity={0.2}
      />
      <Path
        d="M185.709 229.062c.37 1.978.577 3.983.618 5.994l1.421-.537a3.67 3.67 0 013.949.896l.075.074a1.425 1.425 0 001.971.093 1.43 1.43 0 00.494-1.032l.192-6.057-8.72.569z"
        fill="#FFA8A7"
      />
      <Path
        d="M191.197 251.526c-2.948 3.158-8.38 5.92-12.329 3.226l.439 1.007c3.949 2.689 9.381-.068 12.36-3.226 2.978-3.158 1.038-5.345 3.708-8.145a9.576 9.576 0 002.298-3.411c-.117-.297-.234-.692-.37-1.144a12.208 12.208 0 01-2.367 3.554c-2.701 2.824-.785 5.012-3.739 8.139z"
        fill="#F5F5F5"
      />
      <Path
        d="M195.807 236.558a5.855 5.855 0 01-.933-2.151 1.23 1.23 0 00-.581-.877l-.031 1.02a1.425 1.425 0 01-.267.785c-.164.23-.392.406-.657.506a1.438 1.438 0 01-1.548-.352l-.074-.074a3.672 3.672 0 00-3.949-.896l-1.446.543a1.114 1.114 0 00-.717 1.063c.093 4.141-1.854 9.635-3.869 12.657-2.082.865-3.887 3.708-2.966 5.785l.093.21c3.949 2.694 9.381-.068 12.329-3.226 2.947-3.158 1.038-5.34 3.708-8.139a12.255 12.255 0 002.367-3.554 13.196 13.196 0 00-1.459-3.3z"
        fill="#455A64"
      />
      <Path
        d="M190.029 242.534a.503.503 0 00.469-.89 5.773 5.773 0 00-5.673 0 23.53 23.53 0 01-.278.97 5.57 5.57 0 015.482-.08zM190.758 240.297a.505.505 0 00.678-.601.505.505 0 00-.214-.295 6.062 6.062 0 00-5.896.049c-.062.34-.13.674-.204 1.014 1.718-.909 3.455-1.267 5.636-.167zM189.232 244.765a.504.504 0 00.673-.229.5.5 0 00-.21-.667 5.746 5.746 0 00-5.562-.056c-.124.353-.259.693-.395 1.032a5.569 5.569 0 015.494-.08zM181.741 248.782c-2.082.865-3.887 3.708-2.966 5.785l.093.21c3.887 2.651 9.214 0 12.193-3.09-2.484-4.864-7.676-3.653-9.32-2.905zM188.36 246.94a.508.508 0 00.464-.896 5.745 5.745 0 00-5.562-.049c-.179.383-.359.754-.544 1.112 1.724-.908 3.461-1.273 5.642-.167z"
        fill="#fff"
      />
      <Path
        d="M179.504 241.094a36.81 36.81 0 01-1.854 5.741l1.514.087a3.707 3.707 0 013.257 2.416v.093a1.442 1.442 0 001.774.884 1.457 1.457 0 00.865-.748l2.614-5.463-8.17-3.01z"
        fill="#FFA8A7"
      />
      <Path
        d="M175.45 263.886c-3.974 1.699-10.061 2.033-12.582-2.027v1.1c2.521 4.054 8.608 3.708 12.582 2.027s3.09-4.468 6.662-5.957a9.67 9.67 0 003.48-2.194c0-.328.068-.73.123-1.199a11.985 11.985 0 01-3.603 2.292c-3.559 1.49-2.688 4.265-6.662 5.958z"
        fill="#F5F5F5"
      />
      <Path
        d="M185.715 252.033a5.772 5.772 0 010-2.349 1.226 1.226 0 00-.179-1.038l-.439.921a1.447 1.447 0 01-.865.748 1.44 1.44 0 01-1.774-.884v-.093a3.713 3.713 0 00-1.268-1.693 3.713 3.713 0 00-1.989-.723l-1.538-.087a1.117 1.117 0 00-1.088.68c-1.588 3.826-5.562 8.077-8.652 10.024-2.256-.055-5.049 1.817-5.049 4.097v.223c2.521 4.06 8.609 3.708 12.582 2.027 3.974-1.681 3.09-4.468 6.662-5.958a11.985 11.985 0 003.603-2.292 12.866 12.866 0 00-.006-3.603z"
        fill="#455A64"
      />
      <Path
        d="M178.021 255.166a.504.504 0 00.682.058.501.501 0 00.103-.676 5.761 5.761 0 00-5.191-2.28c-.21.267-.427.527-.649.778a5.565 5.565 0 015.055 2.12zM179.591 253.411a.502.502 0 00.785-.63 6.06 6.06 0 00-2.403-1.873 6.057 6.057 0 00-3.011-.463c-.192.284-.396.568-.618.846 1.959-.136 3.696.235 5.247 2.12zM176.396 256.884a.502.502 0 00.785-.618 5.758 5.758 0 00-5.056-2.293c-.253.272-.513.532-.778.785a5.569 5.569 0 015.049 2.126zM167.917 257.539c-2.256-.055-5.049 1.817-5.049 4.097v.223c2.472 3.998 8.423 3.708 12.397 2.101-.303-5.457-5.544-6.446-7.348-6.421zM174.715 258.522a.508.508 0 00.887-.268.498.498 0 00-.102-.363 5.75 5.75 0 00-5.062-2.292c-.315.284-.618.55-.945.797 1.94-.136 3.671.235 5.222 2.126z"
        fill="#fff"
      />
      <Path
        d="M202.852 189.405l-19.893 11.08a9.894 9.894 0 00-4.895 10.407l3.863 20.796-3.164 10.055c2.731 3.665 8.998 3.85 8.998 3.85 3.498-7.014 6.069-9.993 8.034-15.87 1.965-5.877 3.312-9.937 3.312-9.937l16.519-4.512a19.572 19.572 0 0014.313-16.655c.285-2.503.297-4.209.297-4.209l-27.384-5.005z"
        fill="#2C3AD9"
      />
      <Path
        opacity={0.3}
        d="M202.852 189.405l-19.893 11.08a9.894 9.894 0 00-4.895 10.407l3.863 20.796-3.164 10.055c2.731 3.665 8.998 3.85 8.998 3.85 3.498-7.014 6.069-9.993 8.034-15.87 1.965-5.877 3.312-9.937 3.312-9.937l16.519-4.512a19.572 19.572 0 0014.313-16.655c.285-2.503.297-4.209.297-4.209l-27.384-5.005z"
        fill="#000"
      />
      <Path
        opacity={0.1}
        d="M202.852 189.405l-19.893 11.08a9.894 9.894 0 00-4.895 10.407l3.863 20.796-3.164 10.055c2.731 3.665 8.998 3.85 8.998 3.85 3.498-7.014 6.069-9.993 8.034-15.87 1.965-5.877 3.312-9.937 3.312-9.937l16.519-4.512a19.572 19.572 0 0014.313-16.655c.285-2.503.297-4.209.297-4.209l-27.384-5.005z"
        fill="#fff"
      />
      <Path
        opacity={0.2}
        d="M203.001 194.991a9.386 9.386 0 002.224 6.063l-14.034 8.788a10.077 10.077 0 00-4.295 5.599l-4.944 16.247 4.48-18.095a8.213 8.213 0 013.652-5.006l12.978-8.034c-1.372-3.287-.061-5.562-.061-5.562z"
        fill="#000"
      />
      <Path
        d="M189.689 166.563l-4.474 4.042c-2.862-3.652-6.7-6.118-10.055-11.235-.964-1.236-.865-3.665-1.521-4.913-.519-.977-1.421-2.472-1.798-3.269a.533.533 0 00-.145-.194.53.53 0 00-.456-.12.535.535 0 00-.221.097c-.921.705-.42 2.349.074 4.073.587 2.039-2.472-.723-2.904-1.193-1.953-1.817-3.628-6.273-4.944-5.828-1.317.445-.278 2.664-.278 2.664-1.236.26-.761 2.212-.761 2.212-.723 1.28-.519 3.251.971 5.087 1.081 1.328 4.004 3.158 5.525 4.054a5.02 5.02 0 011.582 1.495l9.554 14.251a5.743 5.743 0 008.195 1.391l6.507-4.87-4.851-7.744z"
        fill="#FFA8A7"
      />
      <Path
        opacity={0.3}
        d="M189.689 166.563l-4.474 4.042c-2.862-3.652-6.7-6.118-10.055-11.235-.964-1.236-.865-3.665-1.521-4.913-.519-.977-1.421-2.472-1.798-3.269a.533.533 0 00-.145-.194.53.53 0 00-.456-.12.535.535 0 00-.221.097c-.921.705-.42 2.349.074 4.073.587 2.039-2.472-.723-2.904-1.193-1.953-1.817-3.628-6.273-4.944-5.828-1.317.445-.278 2.664-.278 2.664-1.236.26-.761 2.212-.761 2.212-.723 1.28-.519 3.251.971 5.087 1.081 1.328 4.004 3.158 5.525 4.054a5.02 5.02 0 011.582 1.495l9.554 14.251a5.743 5.743 0 008.195 1.391l6.507-4.87-4.851-7.744z"
        fill="#000"
      />
      <Path
        d="M204.169 155.371c-4.858-.21-11.304 6.996-15.45 11.316a15.915 15.915 0 005.426 8.695 117.782 117.782 0 0010.024-7.904v-12.107z"
        fill="#455A64"
      />
      <Path
        opacity={0.3}
        d="M204.169 155.371c-4.858-.21-11.304 6.996-15.45 11.316a15.915 15.915 0 005.426 8.695 117.782 117.782 0 0010.024-7.904v-12.107z"
        fill="#fff"
      />
      <Path
        d="M230.57 195.962c-3.529 3.392-20.475 4.017-29.121-4.215.829-5.816.55-12.039 0-18.194-.55-6.155-4.153-16.927 2.72-18.182 9.177-1.792 15.172-1.65 21.308 1.805 6.545 10.92 5.655 27.235 5.093 38.786z"
        fill="#455A64"
      />
      <Path
        d="M230.57 195.962c-3.529 3.392-20.475 4.017-29.121-4.215.829-5.816.55-12.039 0-18.194-.55-6.155-4.153-16.927 2.72-18.182 9.177-1.792 15.172-1.65 21.308 1.805 6.545 10.92 5.655 27.235 5.093 38.786z"
        fill="#455A64"
      />
      <Path
        opacity={0.2}
        d="M230.57 195.962c-3.529 3.392-20.475 4.017-29.121-4.215.829-5.816.55-12.039 0-18.194-.55-6.155-4.153-16.927 2.72-18.182 9.177-1.792 15.172-1.65 21.308 1.805 6.545 10.92 5.655 27.235 5.093 38.786z"
        fill="#fff"
      />
      <Path
        d="M216.918 203.273a.786.786 0 010 .265.77.77 0 01-.099.204 1.392 1.392 0 01-.179.204 1.907 1.907 0 01-.272.192l-14.554 8.454a2.31 2.31 0 01-.42.185l-.161.05-.21.055-.26.044h-.531a3.328 3.328 0 01-.346 0h-.056a3.012 3.012 0 01-.34-.056h-.061l-.291-.086h-.056a2.019 2.019 0 01-.321-.155l-24.015-13.862a1.983 1.983 0 01-.272-.191l-.068-.074a1.297 1.297 0 01-.124-.143.623.623 0 01-.049-.092.541.541 0 01-.068-.136.41.41 0 010-.099.772.772 0 010-.136v.853a.772.772 0 000 .136.455.455 0 00.086.235.628.628 0 00.173.228.795.795 0 00.068.074c.083.076.175.142.272.198l24.016 13.862c.102.061.209.113.321.154h.056l.29.087h.062c.112.025.226.044.34.056h.055c.116.006.231.006.347 0h.475l.26-.05.21-.049.161-.05c.146-.048.286-.11.42-.185l14.523-8.411c.096-.054.187-.116.272-.186.065-.063.125-.131.179-.203a.786.786 0 00.099-.204.73.73 0 000-.198v-.927l.068-.043z"
        fill="#E0E0E0"
      />
      <Path
        opacity={0.2}
        d="M216.918 203.273a.786.786 0 010 .265.77.77 0 01-.099.204 1.392 1.392 0 01-.179.204 1.907 1.907 0 01-.272.192l-14.554 8.454a2.31 2.31 0 01-.42.185l-.161.05-.21.055-.26.044h-.531a3.328 3.328 0 01-.346 0h-.056a3.012 3.012 0 01-.34-.056h-.061l-.291-.086h-.056a2.019 2.019 0 01-.321-.155l-24.015-13.862a1.983 1.983 0 01-.272-.191l-.068-.074a1.297 1.297 0 01-.124-.143.623.623 0 01-.049-.092.541.541 0 01-.068-.136.41.41 0 010-.099.772.772 0 010-.136v.853a.772.772 0 000 .136.455.455 0 00.086.235.628.628 0 00.173.228.795.795 0 00.068.074c.083.076.175.142.272.198l24.016 13.862c.102.061.209.113.321.154h.056l.29.087h.062c.112.025.226.044.34.056h.055c.116.006.231.006.347 0h.475l.26-.05.21-.049.161-.05c.146-.048.286-.11.42-.185l14.523-8.411c.096-.054.187-.116.272-.186.065-.063.125-.131.179-.203a.786.786 0 00.099-.204.73.73 0 000-.198v-.927l.068-.043z"
        fill="#000"
      />
      <Path
        d="M174.134 198.143a.541.541 0 00.068.136c.014.032.03.063.049.093.038.05.079.098.124.142l.068.074c.085.072.176.136.272.192l24.015 13.861c.102.062.21.113.322.155h.055l.291.086h.061c.113.025.226.044.34.056h.056c.115.006.231.006.346 0h.476l.259-.043.211-.056.16-.049c.146-.048.287-.11.421-.186l14.572-8.417c.096-.056.187-.12.272-.191.066-.062.126-.131.179-.204a.786.786 0 00.099-.204.792.792 0 000-.266 1.079 1.079 0 00-.618-.822l-24.015-13.862a3.272 3.272 0 00-2.985 0l-14.523 8.405a1.07 1.07 0 00-.618.865.772.772 0 000 .136c.01.035.025.068.043.099z"
        fill="#E0E0E0"
      />
      <Path
        d="M176.13 197.908l23.336 13.448.086.043h.056l.179.056h.445c.08.006.161.006.241 0h.155l.185-.05a.734.734 0 00.161-.068l7.527-4.307-24.102-13.911-8.269 4.789zM198.329 194.207l-4.388 2.583a.44.44 0 000 .766l6.563 3.788a.719.719 0 00.735 0l4.388-2.577a.438.438 0 00.16-.603.432.432 0 00-.16-.163l-6.563-3.794a.724.724 0 00-.735 0z"
        fill="#E0E0E0"
      />
      <Path
        opacity={0.4}
        d="M176.13 197.908l23.336 13.448.086.043h.056l.179.056h.445c.08.006.161.006.241 0h.155l.185-.05a.734.734 0 00.161-.068l7.527-4.307-24.102-13.911-8.269 4.789zM198.329 194.207l-4.388 2.583a.44.44 0 000 .766l6.563 3.788a.719.719 0 00.735 0l4.388-2.577a.438.438 0 00.16-.603.432.432 0 00-.16-.163l-6.563-3.794a.724.724 0 00-.735 0z"
        fill="#000"
      />
      <Path
        d="M200.084 211.782l-2.472-15.808a2.913 2.913 0 00-1.416-2.07l-24.194-14.066a.711.711 0 00-.618-.043l-.575.284.192.074a.698.698 0 00-.062.427l2.472 15.814a2.908 2.908 0 001.415 2.064l24.17 14.066a.845.845 0 00.161.068v.229l.556-.272a.723.723 0 00.371-.767z"
        fill="#E0E0E0"
      />
      <Path
        opacity={0.3}
        d="M200.084 211.782l-2.472-15.808a2.913 2.913 0 00-1.416-2.07l-24.194-14.066a.711.711 0 00-.618-.043l-.575.284.192.074a.698.698 0 00-.062.427l2.472 15.814a2.908 2.908 0 001.415 2.064l24.17 14.066a.845.845 0 00.161.068v.229l.556-.272a.723.723 0 00.371-.767z"
        fill="#000"
      />
      <Path
        d="M195.696 194.145l-24.182-14.06a.731.731 0 00-1.088.748l2.472 15.808a2.955 2.955 0 001.421 2.071l24.183 14.059a.731.731 0 001.039-.343.73.73 0 00.048-.405l-2.472-15.808a2.916 2.916 0 00-1.421-2.07z"
        fill="#E0E0E0"
      />
      <Path
        d="M183.979 194.553a5.339 5.339 0 012.397 3.46c.161 1.236-.655 1.694-1.854 1.014a5.346 5.346 0 01-2.397-3.455c-.136-1.236.686-1.687 1.854-1.019z"
        fill="#fff"
      />
      <Path
        d="M236.107 184.374l-2.596-9.653-9.103 2.027 2.182 7.484-13.343 9.474-4.487 1.891a7.043 7.043 0 00-2.972 2.361l-3.659 5.067a.427.427 0 00-.037.217.699.699 0 001.236.333c.81-.927 1.49-1.786 2.336-2.595l-2.157 2.911a.87.87 0 00.272 1.236c.244.145.506.26.779.339a3.286 3.286 0 003.028-.76l2.824-2.589.779-.272a11.834 11.834 0 005.673-4.258l15.642-6.075a5.978 5.978 0 003.603-7.138z"
        fill="#FFA8A7"
      />
      <Path
        opacity={0.3}
        d="M236.107 184.374l-2.596-9.653-9.103 2.027 2.182 7.484-13.343 9.474-4.487 1.891a7.043 7.043 0 00-2.972 2.361l-3.659 5.067a.427.427 0 00-.037.217.699.699 0 001.236.333c.81-.927 1.49-1.786 2.336-2.595l-2.157 2.911a.87.87 0 00.272 1.236c.244.145.506.26.779.339a3.286 3.286 0 003.028-.76l2.824-2.589.779-.272a11.834 11.834 0 005.673-4.258l15.642-6.075a5.978 5.978 0 003.603-7.138z"
        fill="#000"
      />
      <Path
        d="M234.939 175.982l-.278-1.28c-3.307-15.029-5.945-16.098-10.376-18.156-4.326 3.757-3.591 10.147-3.591 10.147l3.862 11.563.118.334a13.434 13.434 0 0010.265-2.608z"
        fill="#455A64"
      />
      <Path
        opacity={0.3}
        d="M234.939 175.982l-.278-1.28c-3.307-15.029-5.945-16.098-10.376-18.156-4.326 3.757-3.591 10.147-3.591 10.147l3.862 11.563.118.334a13.434 13.434 0 0010.265-2.608z"
        fill="#fff"
      />
      <Path
        d="M218.117 144.519a27.32 27.32 0 001.44-8.454c0-1.533-.155-3.207-1.236-4.326-1.292-1.322-3.504-1.31-5.154-.476-1.65.834-2.862 2.312-4.023 3.745a33.926 33.926 0 002.546 7.243c.581 1.125 1.285 2.268 2.41 2.843 1.125.575 3.016.204 4.017-.575z"
        fill="#455A64"
      />
      <Path
        d="M209.953 162.176c-2.218 1.402-5.098-1.199-2.521-4.994a.141.141 0 00.031-.068v-6.322l8.367-4.616.192 8.448c-.896 2.614-4.141 6.328-6.069 7.552z"
        fill="#FFA8A7"
      />
      <Path
        opacity={0.3}
        d="M209.953 162.176c-2.218 1.402-5.098-1.199-2.521-4.994a.141.141 0 00.031-.068v-6.322l8.367-4.616.192 8.448c-.896 2.614-4.141 6.328-6.069 7.552z"
        fill="#000"
      />
      <Path
        opacity={0.2}
        d="M211.77 152.813c-1.397.673-3.139 1.353-4.326 1.792v-3.813l8.368-4.616c-.05 4.183-1.718 5.512-4.042 6.637z"
        fill="#000"
      />
      <Path
        d="M200.158 134.576a71.03 71.03 0 00.173 12.502 6.17 6.17 0 002.457 4.338 6.176 6.176 0 004.86 1.113 76.553 76.553 0 003.795-.909 5.71 5.71 0 004.054-4.178l.309-1.285a2.624 2.624 0 003.164.742 3.546 3.546 0 002.008-2.726 3.277 3.277 0 00-.148-1.724 2.266 2.266 0 00-1.162-1.236 2.532 2.532 0 00-2.787.797 5.362 5.362 0 00-1.125 2.843 8.77 8.77 0 01-2.2-6.328 3.156 3.156 0 01-2.027-1.039 2.765 2.765 0 01-.519-2.057c-3.269 1.637-7.781 1.192-10.852-.853z"
        fill="#FFA8A7"
      />
      <Path
        opacity={0.3}
        d="M200.158 134.576a71.03 71.03 0 00.173 12.502 6.17 6.17 0 002.457 4.338 6.176 6.176 0 004.86 1.113 76.553 76.553 0 003.795-.909 5.71 5.71 0 004.054-4.178l.309-1.285a2.624 2.624 0 003.164.742 3.546 3.546 0 002.008-2.726 3.277 3.277 0 00-.148-1.724 2.266 2.266 0 00-1.162-1.236 2.532 2.532 0 00-2.787.797 5.362 5.362 0 00-1.125 2.843 8.77 8.77 0 01-2.2-6.328 3.156 3.156 0 01-2.027-1.039 2.765 2.765 0 01-.519-2.057c-3.269 1.637-7.781 1.192-10.852-.853z"
        fill="#000"
      />
      <Path
        d="M205.998 141.571l-2.905 3.523 2.67.939.235-4.462z"
        fill="#F28F8F"
      />
      <Path
        opacity={0.3}
        d="M205.998 141.571l-2.905 3.523 2.67.939.235-4.462z"
        fill="#000"
      />
      <Path
        d="M208.198 147.782l-2.243.686a1.385 1.385 0 001.514.699 1.242 1.242 0 00.729-1.385z"
        fill="#F28F8F"
      />
      <Path
        opacity={0.4}
        d="M208.198 147.782l-2.243.686a1.385 1.385 0 001.514.699 1.242 1.242 0 00.729-1.385z"
        fill="#000"
      />
      <Path
        d="M206.733 149.123c.236.085.492.1.736.044a1.134 1.134 0 00.729-1.014c-.3-.046-.606.008-.871.155a1.347 1.347 0 00-.594.815z"
        fill="#F28F8F"
      />
      <Path
        d="M209.953 142.344a.803.803 0 01-.532.708.8.8 0 01-1.053-.639.8.8 0 011.133-.833.808.808 0 01.408.455c.035.099.05.204.044.309zM203.279 141.726a.799.799 0 01-1.388.496.794.794 0 01.184-1.219.797.797 0 011.204.723zM202.704 138.519l-1.854 1.149a1.145 1.145 0 01.414-1.236 1.338 1.338 0 011.44.087zM209.502 138.784l1.706 1.317a1.153 1.153 0 00-.278-1.236 1.375 1.375 0 00-1.428-.081zM211.393 135.144a2.768 2.768 0 01-.766 1.49 2.182 2.182 0 01-1.57.618 1.73 1.73 0 01-1.446-.835 2.043 2.043 0 01-1.885.835 2.284 2.284 0 01-1.06-.362 2.28 2.28 0 01-.757-.825 2.15 2.15 0 01-2.237.878 2.066 2.066 0 01-1.527-1.786 2.674 2.674 0 01-1.996-.285 1.972 1.972 0 01-.951-1.73 1.58 1.58 0 011.236-1.452 2.072 2.072 0 01.519-2.472 2.026 2.026 0 012.515-.056 1.6 1.6 0 01.946-1.415 1.569 1.569 0 011.668.321 2.51 2.51 0 011.97-2.186 2.512 2.512 0 012.696 1.179 2.17 2.17 0 012.027-1.082 2.039 2.039 0 011.755 1.341 2.522 2.522 0 014.623 1.236 1.56 1.56 0 012.144.124 1.945 1.945 0 01.18 2.231 2.572 2.572 0 01-1.966 1.193c.184.454.197.959.036 1.422a2.023 2.023 0 01-3.521.574 1.503 1.503 0 01-.958 1.594 1.402 1.402 0 01-1.675-.55z"
        fill="#263238"
      />
    </Svg>
  )
}

export default Landing1Svg
